import React, { useEffect, useState } from "react";
import {
  Controller,
  useFormContext,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import {
  accessTokenState,
  loadingState,
  kruValuesState,
  snackBarState,
  userState,
} from "recoil/globalStates";
import { createAdminAllOrgBreedersState } from "recoil/adminStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Local
import { checkIfMicrochipExists, parseEmbarkPage } from "api/privateRoutes";
import { countries } from "constants/countries";
import {
  createHandleInputChange,
  getBreeds,
  getMixes,
  getTypes
} from "constants/helpers";
import { CoatRadio } from "components/registrationComponents/coatRadio.js";
import { CoatCheckbox } from "components/registrationComponents/coatCheckbox.js";

export default function DogInformation({ dog, setDog, formErrors }) {
  const { t, i18n } = useTranslation();
  const { organizationPrefix } = useParams();
  const language = i18n.language;

  const adminAllBreedersOrgState = createAdminAllOrgBreedersState(organizationPrefix);
  const breeders = useRecoilValue(adminAllBreedersOrgState);

  const [inputValue, setInputValue] = useState('');
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const [loading, setLoading] = useRecoilState(loadingState);

  const [formData, setFormData] = useRecoilState(dogRegistrationState);
  const handleInputChange = createHandleInputChange(setFormData);

  const {
    control,
    clearErrors,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext();

  const validateDateOfBirth = (e, field) => {
    let parsedDate = dayjs(e.target.value);
    if (!dayjs(parsedDate).isValid()) {
      setError("dateOfBirth", {
        type: "custom",
        message: t("Invalid date") + " (mm/dd/yyyy)",
      });
    } else if (dayjs(parsedDate).isAfter(dayjs())) {
      setError(name, {
        type: "custom",
        message: t("Date cannot be in the future"),
      });
    } else {
      clearErrors("dateOfBirth");
    }
  };

  const accessToken = useRecoilValue(accessTokenState);
  const user = useRecoilValue(userState);
  const kruValues = useRecoilValue(kruValuesState);
  const breeds = getBreeds(kruValues, language);
  const mixes = getMixes(kruValues, language);
  const types = getTypes(kruValues, language);
  const kruAncestry = kruValues.filter((value) => value.category === "ancestry");

  const {
    embarkURL,
    name,
    microchipNumber,
    declaredBreed,
    declaredMix,
    declaredType1,
    declaredType2,
  } = formData;

// Set country of origin default to user's country
let defaultCountry = countries.find((c) => c.code === user.country);
useEffect(() => {
  if (!formData.countryOfOrigin) {
    setFormData({
      ...formData,
      ["countryOfOrigin"]: defaultCountry,
    });
}
}, []);

/* Lots of declared type stuff */
// Map dog.declaredTypes from kruValue ids to kruValue objects
useEffect(() => {
  const invalid = validateDeclaredTypes();
}, [declaredBreed, declaredMix, declaredType1, declaredType2]);

const validateDeclaredTypes = () => {
  const declared = [declaredBreed, declaredMix, declaredType1, declaredType2].filter(Boolean);
  const clearAllErrors = () => {
    clearErrors("declaredBreed");
    clearErrors("declaredMix");
    clearErrors("declaredType");
  };

  if (0 <= declared.length && declared.length <= 1) {
    clearAllErrors();
    return;
  }

  if (declared.length == 2 && [declaredType1, declaredType2].filter(Boolean).length == 2) {
     clearAllErrors();
    if (declaredType1._id === declaredType2._id) {
      setError("declaredType", { message: t("The two types must not be the same.") });
      return;
    }
    return;
  }

  const msg = t("Breeding description must be a single breed OR a single mix OR 1-2 types.");
  declaredBreed ?
    setError("declaredBreed", { message: msg }) :
    clearErrors("declaredBreed");
  
  declaredMix ?
    setError("declaredMix", { message: msg }) :
    clearErrors("declaredMix");

  (declaredType1 || declaredType2) ?
    setError("declaredType", { message: msg }) :
    clearErrors("declaredType");
  };

  const preloadEmbark = async (event) => {  
    if (!embarkURL) {
      setSnackbarState({
        message: "Please enter a URL (such as http://embk.me/dogname)",
        severity: "error",
        open: true,
      });
      return;
    }
    preloadValues(await handleEmbarkURLChange(embarkURL));
  };

  const handleEmbarkURLChange = async (url) => {
    try {
      setLoading(true);
      let response = await parseEmbarkPage(url, { accessToken });
      setLoading(false);

      if (response.error) {
        let message = "Unable to fetch " + url + " - " + response.message;
        let note = "";
        if (response.message.includes("Only absolute URLs are supported"))
          note = " (Try including http:// or https://)";
        else if (response.message.includes("\"exp\" claim timestamp check failed"))
          note = " (Try reloading the page)";
        setSnackbarState({
          message: message + note,
          severity: "error",
          open: true,
        });
      }

      response.provider = "embark";
      response.source = "website";
      response.url = inputValue;

      setValue("embarkData", response);
      return(response);

    } catch (error) {
      setLoading(false);
      setSnackbarState({
        message: "Error in parsing " + inputValue + ": " + error.message,
        severity: "error",
        open: true,
      });
    }
  };

  const preloadValues = (data) => {
    if (!data) return;
    const userData = data.results.userData;
    const traits = data.results.traits;
    const nameEmbark = userData.name;
    const microchipEmbark = userData.microchip;
    const sexEmbark = !userData.sex ? null :
      userData.sex.toLowerCase() === "f" ? "female" : "male";
    const dobEmbark = userData.dateOfBirth;
    const breedMixEmbark = userData.genotype.breedMix;

    // TODO make sure this works with the formData recoil system
    if (!name) setValue("name", nameEmbark);
    if (!microchipNumber) {
      setValue("microchipNumber", microchipEmbark);
      checkMicrochipNumber({ target: { value: microchipEmbark, name: "microchipNumber" } });
    }
    if (sexEmbark) setValue("sex", sexEmbark);
  
    // So far as I can tell this never gets set
    // if (!dateOfBirth) setValue("dateOfBirth", dobEmbark);

    // traits: WAITING on fixing trait handling
    /* {label: 'E Locus (MC1R)', result: 'No dark mask or grizzle (EE)'} -> mask or grizzle?
{label: 'K Locus (CBD103)', result: 'More likely to have a patterned haircoat (kyky)'}
{label: 'Intensity Loci', result: 'Any light hair likely yellow or tan (Intermediate Red Pigmentation)'}
{label: 'A Locus (ASIP)', result: 'Fawn Sable coat color pattern (ayay)'}
{label: 'D Locus (MLPH)', result: 'Dark areas of hair and skin are not lightened (DD)'}
{label: 'B Locus (TYRP1)', result: 'Black or gray hair and skin (BB)'}
{label: 'Saddle Tan (RALY)', result: 'Not expressed (NN)'}
{label: 'M Locus (PMEL)', result: 'No merle alleles (mm)'}
{label: 'H Locus (Harlequin)', result: 'No harlequin alleles (hh)'}
{label: 'Furnishings (RSPO2)', result: 'Likely unfurnished (no mustache, beard, and/or eyebrows) (II)'}
{label: 'Coat Length (FGF5)', result: 'Likely long coat (LhLh)'}
{label: 'Coat Texture (KRT71)', result: 'Likely straight coat (CC)'}
{label: 'Hairlessness (FOXI3)', result: 'Very unlikely to be hairless (NN)'}
{label: 'Hairlessness (SGK3)', result: 'Very unlikely to be hairless (NN)'} */

    // If barWidthPercentage is 100, it's a purebred and we fill out breed
    // Otherwise we leave it all blank (but could in future make some educated guesses).
    if (!declaredBreed && !declaredMix && !declaredType1 && !declaredType2) {
      const breedWithFullBar = breedMixEmbark.find(entry => entry.barWidthPercentage === 100);
      if (breedWithFullBar) {
        setValue("declaredBreed", breeds.find(b => b.en.name === breedWithFullBar.breedName));
      }
    }
  };

  const checkMicrochipNumber = (e) => {
    if (e.target.value && e.target.value !== "") {
      checkIfMicrochipExists(e.target.value, { accessToken }).then(
        (response) => {
          if (response === true) {
            setError(e.target.name, {
              type: "custom",
              message: t("checkMicrochipNumber"),
            });
          } else {
            clearErrors(e.target.name);
          }
        }
      );
      clearErrors(e.target.name);
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sx={{ mb: 2 }}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Dog's information")}</Typography>
          </Divider>
          <Typography variant="body">
            {t("formInfoText")}
            <b>{t("ownerInfoText3")}</b>
          </Typography>
        </Grid>

{/*
        <Grid item xs={12}>
          <Grid mb={2}>
          <Typography variant="body">
            {t("Enter the URL of the dog's Embark test results, if any:")}
          </Typography>
          </Grid>
          <Grid>
          <Controller
            name="embarkURL"
            control={control}
            defaultValue={embarkURL || ""}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label={t("Enter URL")}
                />
            )}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={preloadEmbark}
            sx={{ mt: 2 }}
          >
            {t("Preload registration data from Embark")}
          </Button>
        </Grid></Grid>
*/ }
        <Grid item xs={5}>
          <Controller
            name="name"
            control={control}
            defaultValue={formData.name || ""}
            rules={{
              required: t("required"),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                value={formData.name}
                label={t("Name")}
                onChange={(e) => handleInputChange(e, field)}
                error={errors?.name}
                helperText={errors?.name?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Controller
            name="microchipNumber"
            control={control}
            defaultValue={formData.microchipNumber || ""}
            rules={{
              required: t("required"),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                required
                value={formData.microchipNumber}
                label={t("microchipNumber")}
                error={errors?.microchipNumber && true}
                helperText={errors && errors?.microchipNumber?.message}
                onChange={(e) => handleInputChange(e, field)}
                onBlur={(e) => {
                  field.onBlur(e);
                  checkMicrochipNumber(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <FormLabel>{t("sex")}</FormLabel>
          <Controller
            name="sex"
            control={control}
            defaultValue={formData.sex || "male"}
            rules={{
              required: t("required"),
            }}
            render={({ field }) => (
              <RadioGroup
                {...field}
                row
                onChange={(e) => handleInputChange(e, field)}
                >
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={<Typography variant="body">{t("male")}</Typography>}
                />
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={<Typography variant="body">{t("female")}</Typography>}
                />
              </RadioGroup>
            )}
          />
        </Grid>

        <Grid item xs={5}>
          <Controller
            name="callname"
            control={control}
            defaultValue={formData.callname || ""}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                value={formData.callname}
                label={t("Call name")}
                onChange={(e) => handleInputChange(e, field)}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="breeder"
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                fullWidth
                options={breeders}
                value={formData.breeder || null}
                getOptionLabel={(option) => `${option.name} (${option.email})`}
                renderOption={(props, option) => (
                  <li {...props} key={option._id}>
                    {option.name} {" ("}
                    {option.email}
                    {") "}
                  </li>
                )}
                isOptionEqualToValue={(option, value) =>
                  option._id == value._id
                }
                renderInput={(params) => (
                <TextField
                    {...params}
                    label={t("Breeder")}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["breeder"]: data
                  });
                }}
                {...props}
              />
            )}
            onChange={([, data]) => data}
            defaultValue={formData.breeder}
            control={control}
          />
        </Grid>


        <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
          <Controller
            name="dateOfBirth"
            control={control}
            defaultValue={formData.dateOfBirth}
            rules={{
              required: t("required"),
            }}
            render={({ field: { ref, onChange, name, value, ...field }, fieldState: { error } }) => (
              <DatePicker
                {...field}
                required
                inputRef={ref}
                label={t("Date of birth")}
                disableFuture
                value={value}
                onChange={(date) => {
                  const formattedDate = dayjs(date).format('MM/DD/YYYY');
                  handleInputChange(
                    {
                      target: {
                        name: name,
                        value: formattedDate,
                      }
                    },
                    field
                  );
                  onChange(formattedDate);
                }}
                error={errors?.dateOfBirth && true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    required
                    value={formData.dateOfBirth}
                    name={name}
                    error={errors?.dateOfBirth && true}
                    helperText={errors && errors?.dateOfBirth?.message}
                    onBlur={(e) => {
                      handleInputChange(e, field);
                      validateDateOfBirth(e, field);
                    }} 
                  />
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
          <Controller
            name="countryOfOrigin"
            control={control}
            defaultValue={
              formData.countryOfOrigin || defaultCountry
            }
            render={({ field: { onChange, ...props } }) => (
              <Autocomplete
                fullWidth
                freeSolo
                label={t("Country of origin")}
                options={countries}
                getOptionLabel={(option) =>
                  option[language] ? option[language] : ""
                }
                value={formData.countryOfOrigin || defaultCountry}
                renderOption={(props, option) => (
                  <li {...props} key={option.code}>
                    {option[language]} {option.code}
                  </li>
                )}
                placeholder={t("Country of origin")}
                isOptionEqualToValue={(option, value) =>
                  option.code == value.code
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Country of origin")}
                    value={formData.countryOfOrigin}
                    />
                )}
                onChange={(e, data) => {
                  onChange(data);
                  setFormData({
                    ...formData,
                    ["countryOfOrigin"]: data
                  });
                }}
                {...props}
              />
            )}
          />
        </Grid>

              <Grid item xs={12}>
              <Divider textAlign="left" sx={{ my: 2 }}>
                <Typography variant="h5">{t("Breeding Description (Breed or Mix)")}</Typography>
                </Divider>
              </Grid>
              <Grid item xs={12} sm={6}>
              <Box mb={2}>
              <Typography variant="body">{t("Select breed (for example, \"Labrador Retriever\" or \"Goldendoodle\")")}:</Typography>
              </Box>
              <Controller
                name="declaredBreed"
                control={control}
                defaultValue={formData.declaredBreed || ""}
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    freeSolo
                    label={t("(Breed")}
                    options={breeds}
                    getOptionLabel={(option) =>
                      option[language] ? option[language].name : ""
                    }
                    value={formData.declaredBreed || ""}
                    onChange={(e, data) => {
                      onChange(data);
                      setFormData({
                        ...formData,
                        ["declaredBreed"]: data
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Breed")}
                        error={!!errors.declaredBreed}
                        helperText={errors.declaredBreed?.message}
                      />
                    )}
                    {...props}
                  />
                )}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <Typography variant="body">{t("OR, select mix (for example, \"Labradoodle\" or \"Pomsky\"):")}</Typography>
              </Box>
              <Controller
                name="declaredMix"
                control={control}
                defaultValue={formData.declaredMix || ""}
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    freeSolo
                    label={t("Mix")}
                    value={formData.declaredMix || ""}
                    options={mixes}
                    getOptionLabel={(option) =>
                      option[language] ? option[language].name : ""
                    }
                    onChange={(e, data) => {
                      onChange(data);
                      setFormData({
                        ...formData,
                        ["declaredMix"]: data
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Mix")}
                        error={!!errors.declaredMix}
                        helperText={errors.declaredMix?.message}
                      />
                    )}
                    {...props}
                  />
                )}
              />
              </Grid>

              <Grid item xs={12} sm={6}>
              <Box mb={2}>
                <Typography variant="body">
                  {t("OR, for mixes without a widely-recognized name, select up to two types that best describe the breed content:")}
                  </Typography>
              </Box>
              <Grid item xs={12}>
              <Box mb={2}>
              <Controller
                key="declaredType1"
                name="declaredType1"
                control={control}
                defaultValue={formData.declaredType1 || ""}
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    freeSolo
                    label="Type"
                    value={formData.declaredType1 || ""}
                    options={types}
                    getOptionLabel={(option) =>
                      option[language] ? option[language].name : ""
                    }
                    onChange={(e, data) => {
                      onChange(data);
                      setFormData({
                        ...formData,
                        ["declaredType1"]: data
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("Type")}
                        error={!!errors.declaredType}
                        helperText={errors.declaredType?.message}
                      />
                    )}
                    {...props}
                  />
                )}
              />
              </Box>
              <Box mb={2}>
              <Controller
                key="declaredType2"
                name="declaredType2"
                control={control}
                defaultValue={formData.declaredType2 || ""}
                render={({ field: { onChange, ...props } }) => (
                  <Autocomplete
                    fullWidth
                    freeSolo
                    value={formData.declaredType2 || ""}
                    label="Type"
                    options={types}
                    getOptionLabel={(option) =>
                      option[language] ? option[language].name : ""
                    }
                    onChange={(e, data) => {
                      onChange(data);
                      setFormData({
                        ...formData,
                        ["declaredType2"]: data
                      });
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option._id === value._id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label={t("Type")} />
                    )}
                    {...props}
                  />
                )}
              />
              </Box>
              </Grid>
              </Grid>

        <Grid item xs={12} sm={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">{t("Coat")}</Typography>
          </Divider>
        </Grid>

        <Grid item xs={6}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend">
            <Typography variant="h5">{t("Base color")}</Typography>
          </FormLabel>
          <Controller
            name="color"
            control={control}
            defaultValue={formData.color}
            render={({ field }) => (
            <RadioGroup
              {...field}
              onChange={(e) => handleInputChange(e, field)}
            >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend">
                  <Typography variant="body">Reds</Typography>
                </FormLabel>
                <CoatRadio
                  category="coat.colors.pheomelanin"
                  kruValues={kruValues}
                  language={language}
                />
              </FormGroup>

              <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend">
                  <Typography variant="body">Brown/Black</Typography>
                </FormLabel>
                <CoatRadio
                  category="coat.colors.eumelanin"
                  kruValues={kruValues}
                  language={language}
                />
                </FormGroup>
                
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">Dilutes</Typography></FormLabel>
                  <CoatRadio
                    category="coat.colors.dilute"
                    kruValues={kruValues}
                    language={language}
                  />
                </FormGroup>
                
                <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormControlLabel
                    key="unknown"
                    value="coat.colors.unknown"
                    control={<Radio />}
                    label={<Typography variant="body">Unknown</Typography>}
                  />
                </FormGroup>
            </RadioGroup>
            )}
          />
          </FormControl>
          </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              <Typography variant="h5">{t("Markings")}</Typography>
            </FormLabel>
            <Controller
              name="whitemarkings"
              control={control}
              defaultValue={formData.whitemarkings}
              render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">White markings</Typography></FormLabel>
                <CoatRadio
                    category="coat.markings.white"
                    kruValues={kruValues}
                    language={language}
                  />
              </FormGroup>
              </RadioGroup>
            )}
          />
        
        <Controller
            name="spots"
            control={control}
            defaultValue={formData.spots}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2, backgroundColor: 'lightgrey' }}>
                <FormLabel component="legend"><Typography variant="body">Spots</Typography></FormLabel>
                <CoatRadio
                    category="coat.markings.spots"
                    kruValues={kruValues}
                    language={language}
                  />
              </FormGroup>
              </RadioGroup>
            )}
          />
        
          <Controller
            name="othermarkings"
            control={control}
            defaultValue={formData.othermarkings}
            render={({ field }) => (
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
                <CoatCheckbox
                  category="coat.markings.other"
                  kruValues={kruValues}
                  language={language}
                  field={field}
                  handleInputChange={handleInputChange}
                />
              </FormGroup>
            )}
          />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">
              <Typography variant="h5">{t("Modifiers")}</Typography>
            </FormLabel>
            <Controller
            name="alocusmodifiers"
            control={control}
            defaultValue={formData.alocusmodifiers}
            render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">A Locus</Typography></FormLabel>
                <CoatRadio
                    category="coat.modifiers.alocus"
                    kruValues={kruValues}
                    language={language}
                  />

                </FormGroup>
                </RadioGroup>
              )}
             />

            <Controller
              name="merlemodifiers"
              control={control}
              defaultValue={formData.merlemodifiers}
              render={({ field }) => (
              <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
              <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
              <FormLabel component="legend"><Typography variant="body">Merle</Typography></FormLabel>
              <CoatRadio
                  category="coat.modifiers.merle"
                  kruValues={kruValues}
                  language={language} />
                </FormGroup>
                </RadioGroup>
              )}
            />

            <Controller
              name="othermodifiers"
              control={control}
              defaultValue={formData.othermodifiers}
              render={({ field }) => (
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
                <CoatCheckbox
                    category="coat.modifiers.other"
                    kruValues={kruValues}
                    language={language}
                    field={field}
                    handleInputChange={handleInputChange}
                  />
                </FormGroup>
              )}
            />
        </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="h5">{t("Coat type")}</Typography>
            </FormLabel>
            <Controller
              name="coattype"
              control={control}
              defaultValue={formData.coattype}
              render={({ field }) => (
                <RadioGroup
                {...field}
                onChange={(e) => handleInputChange(e, field)}
              >
                <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <CoatRadio
                    category="coat.types"
                    kruValues={kruValues}
                    language={language}
                  />

                </FormGroup>
                </RadioGroup>
                )}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel>
              <Typography variant="h5">{t("coatQualities")}</Typography>
            </FormLabel>
            <Controller
              name="coatcurliness"
              control={control}
              defaultValue={formData.coatcurliness}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  onChange={(e) => handleInputChange(e, field)}
                >
                  <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                  <FormLabel component="legend"><Typography variant="body">Curliness</Typography></FormLabel>
                  <CoatRadio
                    category="coat.qualities.curliness"
                    kruValues={kruValues}
                    language={language}
                  />

                  </FormGroup>
                  </RadioGroup>
              )}
            />
            <Controller
              name="coatqualities"
              control={control}
              defaultValue={formData.coatqualities}
              render={({ field }) => (
                <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
                <FormLabel component="legend"><Typography variant="body">{t("Other coat qualities")}</Typography></FormLabel>
                <CoatCheckbox
                    category="coat.qualities.other"
                    kruValues={kruValues}
                    language={language}
                    field={field}
                    handleInputChange={handleInputChange}
                  />
                </FormGroup>
              )}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Divider textAlign="left" sx={{ my: 2 }}>
            <Typography variant="h5">{t("Size")}</Typography>
          </Divider>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="heightIn"
                      control={control}
                      defaultValue={formData.heightIn || ""}
                      rules={{
                        min: {
                          value: 1,
                          message: t("Height must be a positive number"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          label={t("Height - in")}
                          error={!!errors?.heightIn}
                          helperText={errors?.heightIn?.message}
                          onChange={(e) => {
                            field.onChange(e);
                            setFormData({
                              ...formData,
                              height: Math.round(e.target.value * 2.54),
                              heightIn: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="height"
                      control={control}
                      defaultValue={formData.height || ""}
                      rules={{
                        min: {
                          value: 1,
                          message: t("Height must be a positive number"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={formData.height}
                          type="number"
                          label={t("Height - cm")}
                          error={errors?.height && true}
                          helperText={errors && errors?.height?.message}
                          onChange={(e) => {
                            field.onChange(e);
                            setFormData({
                              ...formData,
                              height: e.target.value,
                              heightIn: Math.round(e.target.value / 2.54),
                            });
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        
          <Grid item xs={12} sm={3}>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="weightLb"
                      control={control}
                      defaultValue={formData.weightLb || ""}
                      rules={{
                        min: {
                          value: 1,
                          message: t("Weight must be a positive number"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="number"
                          label={t("Weight - lb")}
                          error={errors?.weightLb && true}
                          helperText={errors && errors?.weightLb?.message}
                          onChange={(e) => {
                            field.onChange(e);
                            setFormData({
                              ...formData,
                              weight: Math.round(e.target.value * 0.453592),
                              weightLb: e.target.value,
                            });
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Controller
                      name="weight"
                      control={control}
                      defaultValue={formData.weight || ""}
                      rules={{
                        min: {
                          value: 1,
                          message: t("Weight must be a positive number"),
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={formData.weight}
                          type="number"
                          label={t("Weight - kg")}
                          error={errors?.weight && true}
                          helperText={errors && errors?.weight?.message}
                          onChange={(e) => {
                            field.onChange(e);
                            setFormData({
                              ...formData,
                              weight: e.target.value,
                              weightLb: Math.round(e.target.value / 0.453592),
                            });
                          }}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
