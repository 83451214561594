import React, { Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilValue } from "recoil";
import { getOrgByPrefixState } from "recoil/publicStates";

// MUI
import { Box, Divider, Grid, Typography } from "@mui/material";

// Components
import OrganizationInfoBox from "components/organizationComponents/OrganizationInfoBox";
import OrganizationBreeders from "components/organizationComponents/organizationBreeders";
import FDCLogo from "images/fdc_logo.png";

export default function OrganizationProfile() {
  const { t } = useTranslation();
  const { organizationPrefix } = useParams();

  const org = useRecoilValue(getOrgByPrefixState(organizationPrefix));

if (org === null) {
throw new Error ("No organization found with prefix " + prefix);
}

  return (
    <>
    <Grid item xs={12} sm={9}>
      <Box
        sx={{ py: 1, height: 100 }}
        component="img"
        src={FDCLogo}
        alt="{org.name} logo"
      />
    </Grid>

    <OrganizationInfoBox org={org}/>
      <Grid container direction={{ xs: "column", md: "row" }} spacing={5}>
        <Grid item xs={7}>
          <Divider textAlign="left" sx={{ mb: 2 }}>
            <Typography variant="h2">{t("Breeders")}</Typography>
          </Divider>
          <Suspense fallback="{<Loader />}">
            <OrganizationBreeders org={org} />
            </Suspense>
        </Grid>
      </Grid>
      </>
  );
}
