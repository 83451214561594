import React, { Suspense } from "react";
import { useParams } from "react-router-dom";
import { Outlet } from "react-router";
import { useTranslation } from "react-i18next";

import { useAuth0 } from "@auth0/auth0-react";

// Recoil
import { useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";

// MUI
import {
  Grid,
  Paper,
  Typography,
  Link,
  Box,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";

// Local
import { LoaderBlock } from "elements/Loader";
import AdminNav from "components/adminDashboardComponents/adminNav";
import { compareRole } from "constants/helpers";

function AdminContainer() {
  const { organizationPrefix } = useParams();
  const { isAuthenticated } = useAuth0();
  const dbUser = useRecoilValue(userState);
  const canAccessDashboard = compareRole(dbUser.role, "manager");

  const theme = useTheme();
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("md"));
  const padding = lessThanSmall ? 2 : 4;
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={3} justifyContent="center">
        {isAuthenticated && canAccessDashboard ? (
          <>
            <Grid item xs={12}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={3}
                alignItems={{ xs: "center", sm: "baseline" }}
              >
                <Typography
                  component={Link}
                  href={`/${organizationPrefix}/admin/dashboard`}
                  variant="h1"
                  sx={{
                    color: "primary",
                    textDecoration: "none",
                  }}
                >
                  FunctionalDogData {t("admindashboard")}
                </Typography>
                <Typography variant="caption">as {dbUser.role}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <AdminNav organizationPrefix={organizationPrefix}/>
            </Grid>
            <Grid item xs={12}>
              <Suspense
                fallback={
                  <Box sx={{ p: 4 }}>
                    <LoaderBlock />
                  </Box>
                }
              >
                <Paper sx={{ p: padding }}>
                  <Outlet />
                </Paper>
              </Suspense>
            </Grid>
          </>
        ) : (
          <Typography variant="body">
            Insufficient permissions, contact administrator.
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default AdminContainer;
