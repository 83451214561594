import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getOrganizationByPrefix } from "api/publicRoutes";
import { AdminNotFound } from "components/adminDashboardComponents/adminNotFound";
import OrganizationForm from "../forms/organizationForm";

function AdminOrganizationView() {
  const { organizationPrefix } = useParams();
  const [org, setOrg] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const orgData = await getOrganizationByPrefix(organizationPrefix);
        setOrg(orgData);
      } catch (error) {
        console.error("Error fetching organization:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganization();
  }, [organizationPrefix]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return org ? (
    <OrganizationForm action="edit" org={org} />
  ) : (
    <AdminNotFound docType="Organization" />
  );
}

export default AdminOrganizationView;