import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import {
  useRecoilValue,
} from "recoil";
import {
  kruValuesState,
} from "recoil/globalStates";

// MUI
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

function AdminDogAppearance({ dog, formMethods }) {
  const [initialDog, setInitialDog] = useState(dog);
  const { t, i18n } = useTranslation();
  const kruValues = useRecoilValue(kruValuesState);
  const { control, setValue, formState: { errors } } = formMethods;

  const whitemarkings = initialDog.coat.markings.find(marking =>
    marking.startsWith("coat.markings.white")
  );

  const spots = initialDog.coat.markings.find(marking =>
    marking.startsWith("coat.markings.spots")
  );

  const othermarkings = initialDog.coat.markings.filter(marking =>
    marking.startsWith("coat.markings.other")
  );

  const alocusmodifiers = initialDog.coat.modifiers.find(marking =>
    marking.startsWith("coat.modifiers.alocus")
  );

  const merlemodifiers = initialDog.coat.modifiers.find(marking =>
    marking.startsWith("coat.modifiers.merle")
  );

  const othermodifiers = initialDog.coat.modifiers.filter(marking =>
    marking.startsWith("coat.modifiers.other")
  );

  const coatcurliness = initialDog.coat.qualities.find(marking =>
    marking.startsWith("coat.qualities.curliness")
  );

  const coatqualities = initialDog.coat.qualities.filter(marking =>
    marking.startsWith("coat.qualities.other")
  );

  return (
    <>

<Grid item xs={12} sm={12}>
  <Divider textAlign="left" sx={{ my: 2 }}>
    <Typography variant="h5">{t("Coat")}</Typography>
  </Divider>
</Grid>

<Grid item xs={6}>
<FormControl component="fieldset" fullWidth>
  <FormLabel component="legend">
    <Typography variant="h5">{t("Base color")}</Typography>
  </FormLabel>
  <Controller
    name="color"
    control={control}
    defaultValue={initialDog?.coat.color}
    render={({ field }) => (
    <RadioGroup {...field}>
      <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormLabel component="legend"><Typography variant="body">Reds</Typography></FormLabel>
        <FormControlLabel
          key="cream"
          value="coat.colors.cream"
          control={<Radio />}
          label="Cream"
        />
        <FormControlLabel
          key="yellow"
          value="coat.colors.yellow"
          control={<Radio />}
          label="Yellow"
        />
        <FormControlLabel
          key="red"
          value="coat.colors.red"
          control={<Radio />}
          label="Red"
        />
      </FormGroup>
      <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormLabel component="legend"><Typography variant="body">Brown/Black</Typography></FormLabel>
        <FormControlLabel
          key="liver"
          value="coat.colors.liver"
          control={<Radio />}
          label="Liver"
        />
        <FormControlLabel
          key="black"
          value="coat.colors.black"
          control={<Radio />}
          label="Black"
        />
      </FormGroup>
      <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormLabel component="legend"><Typography variant="body">Dilutes</Typography></FormLabel>
        <FormControlLabel
          key="blue"
          value="coat.colors.blue"
          control={<Radio />}
          label="Blue"
        />
        <FormControlLabel
          key="isabella"
          value="coat.colors.isabella"
          control={<Radio />}
          label="Isabella"
        />
      </FormGroup>
      <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormControlLabel
          key="unknown"
          value="coat.colors.unknown"
          control={<Radio />}
          label="Unknown"
        />
      </FormGroup>
    </RadioGroup>
    )}
  />
  </FormControl>
  </Grid>

<Grid item xs={6}>
  <FormControl component="fieldset" fullWidth>
    <FormLabel component="legend">
      <Typography variant="h5">{t("Markings")}</Typography>
    </FormLabel>
    <Controller
      name="whitemarkings"
      control={control}
      defaultValue={whitemarkings}
      render={({ field }) => (
        <RadioGroup {...field}>
      <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormLabel component="legend"><Typography variant="body">White markings</Typography></FormLabel>
        <FormControlLabel
          key="extremewhite"
          value="coat.markings.white.extremewhite"
          control={<Radio />}
          label="Extreme white"
        />
        <FormControlLabel
          key="piebald"
          value="coat.markings.white.piebald"
          control={<Radio />}
          label="Piebald"
        />
        <FormControlLabel
          key="irishspotting"
          value="coat.markings.white.irishspotting"
          control={<Radio />}
          label="Irish spotting"
        />
        <FormControlLabel
          key="abstract"
          value="coat.markings.white.abstract"
          control={<Radio />}
          label="Abstract white"
        />
        <FormControlLabel
          key="smallwhitemarks"
          value="coat.markings.white.smallwhitemarks"
          control={<Radio />}
          label="Small white marks"
        />
        <FormControlLabel
          key="nowhite"
          value="coat.markings.white.nowhite"
          control={<Radio />}
          label="No white markings"
        />
      </FormGroup>
      </RadioGroup>
    )}
  />

<Controller
    name="spots"
    control={control}
    defaultValue={spots}
    render={({ field }) => (
      <RadioGroup {...field}>
      <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2, backgroundColor: 'lightgrey' }}>
        <FormLabel component="legend"><Typography variant="body">Spots</Typography></FormLabel>
        <FormControlLabel
          key="ticking"
          value="coat.markings.spots.ticking"
          control={<Radio />}
          label="Ticking"
        />
        <FormControlLabel
          key="roan"
          value="coat.markings.spots.roan"
          control={<Radio />}
          label="Roan"
        />
        <FormControlLabel
          key="spots"
          value="coat.markings.spots.spots"
          control={<Radio />}
          label="Spots"
        />
        <FormControlLabel
          key="nospots"
          value="coat.markings.spots.nospots"
          control={<Radio />}
          label="No Spots"
        />
      </FormGroup>
      </RadioGroup>
    )}
  />

<Controller
  name="othermarkings"
  control={control}
  defaultValue={othermarkings}
  render={({ field: { onChange, value, ...field } }) => (
    <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
      <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
      <FormControlLabel
        key="mask"
        control={
          <Checkbox
            id="coat.markings.other.mask"
            checked={value.includes("coat.markings.other.mask")}
            onChange={(e) => {
              const newValue = e.target.checked
                ? [...value, "coat.markings.other.mask"]
                : value.filter((item) => item !== "coat.markings.other.mask");
              onChange(newValue);
            }}
          />
        }
        label="Mask"
      />
      <FormControlLabel
        key="tanpoints"
        control={
          <Checkbox
            id="coat.markings.other.tanpoints"
            checked={value.includes("coat.markings.other.tanpoints")}
            onChange={(e) => {
              const newValue = e.target.checked
                ? [...value, "coat.markings.other.tanpoints"]
                : value.filter((item) => item !== "coat.markings.other.tanpoints");
              onChange(newValue);
            }}
          />
        }
        label="Tan Points"
      />
      <FormControlLabel
        key="grizzle"
        control={
          <Checkbox
            id="coat.markings.other.grizzle"
            checked={value.includes("coat.markings.other.grizzle")}
            onChange={(e) => {
              const newValue = e.target.checked
                ? [...value, "coat.markings.other.grizzle"]
                : value.filter((item) => item !== "coat.markings.other.grizzle");
              onChange(newValue);
            }}
          />
        }
        label="Grizzle"
      />
      <FormControlLabel
        key="brindle"
        control={
          <Checkbox
            id="coat.markings.other.brindle"
            checked={value.includes("coat.markings.other.brindle")}
            onChange={(e) => {
              const newValue = e.target.checked
                ? [...value, "coat.markings.other.brindle"]
                : value.filter((item) => item !== "coat.markings.other.brindle");
              onChange(newValue);
            }}
          />
        }
        label="Brindle"
      />
    </FormGroup>
  )}
/>
  </FormControl>
</Grid>

<Grid item xs={6}>
  <FormControl component="fieldset" fullWidth>
    <FormLabel component="legend">
      <Typography variant="h5">{t("Modifiers")}</Typography>
    </FormLabel>
    <Controller
      name="alocusmodifiers"
      defaultValue={alocusmodifiers}
      control={control}
      render={({ field }) => (
      <RadioGroup {...field}>
      <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormLabel component="legend"><Typography variant="body">A Locus</Typography></FormLabel>
        <FormControlLabel
          key="sable"
          value="coat.modifiers.alocus.sable"
          control={<Radio />}
          label="Sable"
        />
        <FormControlLabel
          key="agouti"
          value="coat.modifiers.alocus.agouti"
          control={<Radio />}
          label="Agouti"
        />
        <FormControlLabel
          key="tanpoints"
          value="coat.modifiers.alocus.tanpoints"
          control={<Radio />}
          label="Tan points"
        />
        <FormControlLabel
          key="saddle"
          value="coat.modifiers.alocus.saddle"
          control={<Radio />}
          label="Saddle"
        />
        </FormGroup>
        </RadioGroup>
      )}
      />

    <Controller
      name="merlemodifiers"
      control={control}
      defaultValue={merlemodifiers}
      render={({ field }) => (
      <RadioGroup {...field}>
      <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
      <FormLabel component="legend"><Typography variant="body">Merle</Typography></FormLabel>
      <FormControlLabel
          key="merle"
          value="coat.modifiers.merle.merle"
          control={<Radio />}
          label="Merle"
        />
        <FormControlLabel
          key="harlequin"
          value="coat.modifiers.merle.harlequin"
          control={<Radio />}
          label="Harlequin"
        />
        <FormControlLabel
          key="notmerle"
          value="coat.modifiers.merle.notmerle"
          control={<Radio />}
          label="Not visibly merle"
        />
        </FormGroup>
        </RadioGroup>
      )}
    />

    <Controller
      name="othermodifiers"
      control={control}
      defaultValue={othermodifiers}
      render={({ field: { onChange, value, ...field } }) => (
        <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
          <FormLabel component="legend"><Typography variant="body">Other</Typography></FormLabel>
          <FormControlLabel
            key="grey"
            control={
              <Checkbox
                id="coat.modifiers.other.grey"
                checked={value.includes("coat.modifiers.other.grey")}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...value, "coat.modifiers.other.grey"]
                    : value.filter((item) => item !== "coat.modifiers.other.grey");
                  onChange(newValue);
                }}
              />
            }
            label="Greying"
          />
          <FormControlLabel
            key="brindle"
            control={
              <Checkbox
                id="coat.modifiers.other.brindle"
                checked={value.includes("coat.modifiers.other.brindle")}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...value, "coat.modifiers.other.brindle"]
                    : value.filter((item) => item !== "coat.modifiers.other.brindle");
                  onChange(newValue);
                }}
              />
            }
            label="Brindle"
          />
          <FormControlLabel
            key="seal"
            control={
              <Checkbox
                id="coat.modifiers.other.seal"
                checked={value.includes("coat.modifiers.other.seal")}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...value, "coat.modifiers.other.seal"]
                    : value.filter((item) => item !== "coat.modifiers.other.seal");
                  onChange(newValue);
                }}
              />
            }
            label="Seal"
          />
        </FormGroup>
      )}
    />
</FormControl>
</Grid>

<Grid item xs={6}>
  <FormControl fullWidth>
    <FormLabel>
      <Typography variant="h5">{t("Coat type")}</Typography>
    </FormLabel>
    <Controller
      name="coattype"
      control={control}
      defaultValue={initialDog?.coat.type}
      render={({ field }) => (
        <RadioGroup {...field}>
        <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
        <FormControlLabel
          key="short"
          value="coat.types.short"
          control={<Radio />}
          label="Short"
        />
        <FormControlLabel
          key="long"
          value="coat.types.long"
          control={<Radio />}
          label="Long"
        />
        <FormControlLabel
          key="hairless"
          value="coat.types.hairless"
          control={<Radio />}
          label="Hairless"
        />
        </FormGroup>
        </RadioGroup>
        )}
    />
  </FormControl>

  <FormControl fullWidth>
    <FormLabel>
      <Typography variant="h5">{t("coatQualities")}</Typography>
    </FormLabel>
    <Controller
      name="coatcurliness"
      control={control}
      defaultValue={coatcurliness}
      render={({ field }) => (
        <RadioGroup {...field}>
          <FormGroup sx={{ border: '1px solid black', padding: 2, borderRadius: 1, marginBottom: 2 }}>
          <FormLabel component="legend"><Typography variant="body">Curliness</Typography></FormLabel>
          <FormControlLabel
            key="straight"
            value="coat.qualities.curliness.straight"
            control={<Radio />}
            label="Straight"
          />
          <FormControlLabel
            key="wavy"
            value="coat.qualities.curliness.wavy"
            control={<Radio />}
            label="Wavy"
          />
          <FormControlLabel
            key="curly"
            value="coat.qualities.curliness.curly"
            control={<Radio />}
            label="Curly"
          />
          </FormGroup>
          </RadioGroup>
      )}
    />
    <Controller
      name="coatqualities"
      control={control}
      defaultValue={coatqualities}
      render={({ field: { onChange, value, ...field } }) => (
        <FormGroup sx={{ border: '1px solid black', backgroundColor: 'lightgrey', padding: 2, borderRadius: 1, marginBottom: 2 }}>
          <FormLabel component="legend"><Typography variant="body">{t("Other coat qualities")}</Typography></FormLabel>
          <FormControlLabel
            key="furnishings"
            control={
              <Checkbox
                id="coat.qualities.other.furnishings"
                checked={value.includes("coat.qualities.other.furnishings")}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...value, "coat.qualities.other.furnishings"]
                    : value.filter((item) => item !== "coat.qualities.other.furnishings");
                  onChange(newValue);
                }}
              />
            }
            label="Furnishings"
          />
          <FormControlLabel
            key="undercoat"
            control={
              <Checkbox
                id="coat.qualities.other.undercoat"
                checked={value.includes("coat.qualities.other.undercoat")}
                onChange={(e) => {
                  const newValue = e.target.checked
                    ? [...value, "coat.qualities.other.undercoat"]
                    : value.filter((item) => item !== "coat.qualities.other.undercoat");
                  onChange(newValue);
                }}
              />
            }
            label="Undercoat"
          />
        </FormGroup>
      )}
    />
  </FormControl>
</Grid>

<Grid item xs={12}>
<Divider textAlign="left" sx={{ my: 2 }}>
  <Typography variant="h5">{t("Size")}</Typography>
</Divider>
</Grid>

<Grid container spacing={2}>
    <Grid item xs={12} sm={3}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="heightIn"
                control={control}
                defaultValue={initialDog?.height > 0 ? Math.round(initialDog?.height / 2.54) : null}
                rules={{
                  min: {
                    value: 1,
                    message: t("Height must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Height - in")}
                    error={errors?.heightIn && true}
                    helperText={errors && errors?.heightIn?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('height', Math.round(e.target.value * 2.54));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="height"
                control={control}
                defaultValue={initialDog?.height}
                rules={{
                  min: {
                    value: 1,
                    message: t("Height must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Height - cm")}
                    error={errors?.height && true}
                    helperText={errors && errors?.height?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('heightIn', e.target.value === null ? null : Math.round(e.target.value / 2.54));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>

    <Grid item xs={12} sm={3}>
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="weightLb"
                control={control}
                defaultValue={initialDog?.weight > 0 ? Math.round(initialDog?.weight / 0.453592) : null}
                rules={{
                  min: {
                    value: 1,
                    message: t("Weight must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Weight - lb")}
                    error={errors?.weightLb && true}
                    helperText={errors && errors?.weightLb?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('weight', e.target.value === null ? null : Math.round(e.target.value * 0.453592));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Controller
                name="weight"
                control={control}
                defaultValue={initialDog?.weight}
                rules={{
                  min: {
                    value: 1,
                    message: t("Weight must be a positive number"),
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="number"
                    label={t("Weight - kg")}
                    error={errors?.weight && true}
                    helperText={errors && errors?.weight?.message}          
                    onChange={(e) => {
                      field.onChange(e);
                      setValue('weightLb', Math.round(e.target.value / 0.453592));
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  </Grid>
</>  );
}

export default AdminDogAppearance;
