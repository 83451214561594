import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { getOrgByPrefixState } from "recoil/publicStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItemButton,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Launch } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

// Local
import {
  createHandleInputChange,
} from "constants/helpers";

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export default function dogRegistry() {
  const { organizationPrefix } = useParams();
  const org = useRecoilValue(getOrgByPrefixState(organizationPrefix));

  if (!org) {
    throw new Error(`No organization found with prefix ${organizationPrefix}`);
  }

  const { t } = useTranslation();
  const location = useLocation();
  const targetPath = `/organization/${org.prefix}`;
  const [formData, setFormData] = useRecoilState(dogRegistrationState);
  const methods = useFormContext();
  const {
    control,
    formState: { errors, isSubmitting, isDirty },
  } = methods;
  const handleInputChange = createHandleInputChange(setFormData);
  
  return (
    <>
    <Paper sx={{ mb: 0, p: 3 }}>
      <Grid
        container
        sx={{ mb: 0 }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={9}>
          { /* This is where org logo would go
          <Box
            sx={{ py: 1, height: 100 }}
            component="img"
            src={FDCLogo}
            alt="{org.name} logo"
          />
          */ }
       <Typography variant="h1">
        {location.pathname === targetPath ? (
          org.name
        ) : (
          <StyledListButton href={targetPath} style={{ textDecoration: 'none' }}>
            {org.name}
          </StyledListButton>
        )}
      </Typography>
           <Typography variant="body1">
            {org.url && <Link href={org.url} style={{ textDecoration: 'none' }}>{org.url}</Link>}
          </Typography>
  
        </Grid>
        
        <Grid item xs={6} sx={{ mb: 0 }}>
        {org && (
          <Button href={org.rulesurl} target="_blank" endIcon={<Launch />}>
            {t("registryGuidelines")}
          </Button>
        )}
      </Grid>
    </Grid>
  </Paper>
  <Grid item xs={6} sx={{ mt: 6 }}>
    <FormLabel>
      <Typography variant="h5">{t("Registration Class")}</Typography>
    </FormLabel>
  <Controller
    name="class"
    control={control}
    defaultValue={formData.class || "reg.classes.full"}
    rules={{
      required: t("required"),
    }}
    render={({ field }) => (
      <RadioGroup
        {...field}
        row
        onChange={(e) => handleInputChange(e, field)}
        >
        <FormControlLabel
          value="reg.classes.full"
          control={<Radio />}
          label={<Typography variant="body">{t("I am the dog's owner.")}</Typography>}
        />
        <FormControlLabel
          value="reg.classes.unregparent"
          control={<Radio />}
          label={<Typography variant="body">{t("I am not the dog's owner, and am adding them as an unregistered parent for a litter.")}</Typography>}
        />
      </RadioGroup>
    )}
  />
</Grid>
</>
  );
}
