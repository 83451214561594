import React from "react";
import { useParams } from "react-router-dom";

import { getPendingLitterColumns } from "components/adminDashboardComponents/adminDatagridColumns";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { adminPendingLittersState } from "recoil/adminStates";
import { useRecoilValue } from "recoil";

export default function PendingLittersWidget() {
  const { organizationPrefix } = useParams();
  const pendingLitters = useRecoilValue(adminPendingLittersState);

  const handleRowClick = (params) => {
    window.location = `/${organizationPrefix}/admin/litter/${params.row._id}`;
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} id="pendingLitters">
          <Typography variant="h3">
            Pending litter registrations
            <Chip
              color="primary"
              size="small"
              label={pendingLitters.length}
              sx={{ ml: 2 }}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={pendingLitters}
            getRowId={(row) => row._id}
            columns={getPendingLitterColumns("pendinglitter")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
