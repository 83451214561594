import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Recoil
import { constSelector, useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";

// MUI
import { Button, Menu, MenuItem, Stack, Divider } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";

function AdminNav( ) {
  const { organizationPrefix } = useParams();
  
  const [items, setItems] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { isAuthenticated } = useAuth0();
  const user = useRecoilValue(
    isAuthenticated ? userState : constSelector(null)
  );

  // TODO: Add keys
  const dogItems = [
    <MenuItem divider key={"alldogs"} component={Button}
      href={`/${organizationPrefix}/admin/alldogs`}>
      Registered Dogs
    </MenuItem>,
    <MenuItem
      divider
      key={"allpendingdogs"}
      component={Button}
      href={`/${organizationPrefix}/admin/allpendingdogs`}
    >
      Pending Dogs
    </MenuItem>,
    <MenuItem key={"alltreedogs"} component={Button} href={`/${organizationPrefix}/admin/alltreedogs`}>
      Tree Dogs
    </MenuItem>,
  ];
  const litterItems = [
    <MenuItem key={0} component={Button} divider href={`/${organizationPrefix}/admin/alllitters`}>
      Registered Litters
    </MenuItem>,
    <MenuItem key={1} component={Button} href={`/${organizationPrefix}/admin/allpendinglitters`}>
      Pending Litters
    </MenuItem>,
  ];
  const resultItems = [
    <MenuItem key={0} component={Button} divider href={`/${organizationPrefix}/admin/allhealthresults`}>
      Health results
    </MenuItem>,
    <MenuItem
      key={1}
      component={Button}
      divider
      href={`/${organizationPrefix}/admin/allgeneticresults`}
    >
      Genetic results
    </MenuItem>,
    <MenuItem key={2} component={Button} href={`/${organizationPrefix}/admin/allcompetitionresults`}>
      Competition results
    </MenuItem>,
  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (event.target.name === "dogs") {
      setItems(dogItems);
    } else if (event.target.name === "litters") {
      setItems(litterItems);
    } else if (event.target.name === "results") {
      setItems(resultItems);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack
        flexWrap={"wrap"}
        divider={<Divider orientation="vertical" flexItem />}
        direction={"row"}
        spacing={1}
        justifyContent={{ xs: "center", sm: "flex-start" }}
      >
        <Button name="dashboard" href={`/${organizationPrefix}/admin/dashboard`}>
          Dashboard
        </Button>
        <Button name="dogs" onClick={handleClick} endIcon={<ArrowDropDown />}>
          Dogs
        </Button>
        <Button
          name="litters"
          onClick={handleClick}
          endIcon={<ArrowDropDown />}
        >
          Litters
        </Button>
        <Button name="users" href={`/${organizationPrefix}/admin/allusers`}>
          Users
        </Button>
        {user.role === "admin" && (
          <Button name="organizations" href={`/${organizationPrefix}/admin/organization`}>
            Organization ({organizationPrefix})
          </Button>
        )}
        {user.role === "admin" && (
          <Button name="resultproviders" href={`/${organizationPrefix}/admin/allresultproviders`}>
            Result providers
          </Button>
        )}
        <Button name="certificates" href={`/${organizationPrefix}/admin/allcertificates`}>
          Certificates
        </Button>
        { /* TODO uncomment when ready
        <Button
          name="results"
          onClick={handleClick}
          endIcon={<ArrowDropDown />}
        >
          Results
        </Button> */}
        <Button name="pedigreetool" href={`/${organizationPrefix}/admin/pedigreetool`}>
          Pedigree Tool
        </Button>

        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ p: 6 }}
        >
          {items}
        </Menu>
      </Stack>
    </>
  );
}
export default AdminNav;