import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import {
  Grid,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Autocomplete,
  InputLabel,
  Typography,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { deleteHealthResult } from "api/adminRoutes";
import DogAutocomplete from "components/formComponents/dogAutocomplete";
import { HealthtestComponent } from "components/HealthTestComponent";

import {
  accessTokenState,
  snackBarState,
  kruValuesState,
} from "recoil/globalStates";
import { adminAllDogsState, adminDogByIdState } from "recoil/adminStates";
import { useRecoilState, useRecoilValue } from "recoil";
import { Controller, useForm } from "react-hook-form";
import FormHeader from "components/adminDashboardComponents/formHeader";
import JsonPreviewWidget from "components/adminDashboardComponents/jsonPreviewWidget";
import FormButtons from "components/adminDashboardComponents/formButtons";
import {
  GetHealthtestDiscriminator,
  isFormatSidesOrGrade,
} from "constants/healthtestFormat";
import { allResultProvidersState } from "recoil/publicStates";

function HealthtestForm({
  action,
  result,
  emitHandleSubmit,
  handleModalClose,
}) {
  const { organizationPrefix } = useParams();
  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const kruValues = useRecoilValue(kruValuesState);

  const providers = useRecoilValue(allResultProvidersState);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const dogs = useRecoilValue(adminAllDogsState);
  const [selectedDog, setSelectedDog] = useState(
    useRecoilValue(adminDogByIdState(result?.parentDoc))
  );

  const [initialResult, setInitialResult] = useState(result);
  const [resultType, setResultType] = useState(initialResult?.result?.type);

  useEffect(() => {
    setResultType(GetHealthtestDiscriminator(formdata.subject));
  }, [formdata.subject]);

  useEffect(() => {
    // if form is touched, reset the result values
    // with this switch statement, we can set the result object to be either
    // two-sided (left, right), one-sided (grade) or ecvo eye examination result

    if (isDirty) {
      const format = isFormatSidesOrGrade(resultType);

      if (format === "twoSides") {
        setValue("result", {
          left: "",
          right: "",
        });
      } else if (format === "grade") {
        setValue("result", {
          grade: "",
        });
      } else if (format === "ecvo") {
        setValue("result", {
          ppm: "",
          phtvlPhpv: "",
          cataractCon: "",
          rd: "",
          hypoMicroPapilla: "",
          cea: "",
          icaa: "",
          entropion: "",
          ectropion: "",
          distichiasis: "",
          cornealDystrophy: "",
          cataractNonCon: "",
          lensLuxationPrimary: "",
          pra: "",
          other: "",
        });
      }
    }
  }, [resultType]);

  const handleEcvoAllClear = () => {
    setValue("result", {
      ppm: "unaffected",
      phtvlPhpv: "unaffected",
      cataractCon: "unaffected",
      rd: "unaffected",
      hypoMicroPapilla: "unaffected",
      cea: "unaffected",
      icaa: "unaffected",
      entropion: "unaffected",
      ectropion: "unaffected",
      distichiasis: "unaffected",
      cornealDystrophy: "unaffected",
      cataractNonCon: "unaffected",
      lensLuxationPrimary: "unaffected",
      pra: "unaffected",
      other: "unaffected",
    });
  };

  const onSubmit = (data) => {
    // map each object as _id before sending it to API
    data.parentDoc = data.parentDoc._id;
    data.source = data.source._id;
    // set parentDocType, otherwise result's organizations won't be set
    data.parentDocType = "dog";

    // set result's format aka discriminator
    data.result = { ...data.result, type: resultType };

    emitHandleSubmit(accessToken, data, initialResult?._id);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this result? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteHealthResult(initialResult._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Result has been removed.`);
              setTimeout((window.location = `/${organizationPrefix}/admin/allhealthresults`), 3000);
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="healthresult"
              data={initialResult}
              handleModalClose={handleModalClose}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="date"
              control={control}
              defaultValue={initialResult?.date || null}
              rules={{
                required: "Required",
              }}
              render={({ field: { ref, name, ...field } }) => (
                <DatePicker
                  {...field}
                  required
                  label="Date"
                  inputRef={ref}
                  onChange={(e) => field.onChange(dayjs(e).toISOString())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      error={errors?.date}
                      helperText={errors?.date && errors?.date?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DogAutocomplete
              label="Dog"
              name="parentDoc"
              options={dogs}
              value={selectedDog}
              required={true}
              error={errors?.parentDoc}
              helperText={errors?.parentDoc && errors?.parentDoc?.message}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel>Subject</InputLabel>
              <Controller
                name="subject"
                control={control}
                defaultValue={initialResult?.subject || ""}
                rules={{
                  required: "Required",
                }}
                render={({ field }) => (
                  <Select {...field} fullWidth label="Subject">
                    {kruValues
                      .filter((i) => i.category === "healthtest.subjects")
                      .map((value) => (
                        <MenuItem key={value._id} value={value._id}>
                          {value.en.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="source"
              onChange={([, data]) => data}
              defaultValue={initialResult?.source || null}
              control={control}
              rules={{ required: "Required" }}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  required
                  label="Source"
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                  options={providers}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <TextField {...params} required label="Source" />
                  )}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Result
            </Typography>
            {action === "add" && resultType === "eyesEcvo" && (
              <Button onClick={handleEcvoAllClear} sx={{ float: "right" }}>
                Add all clear
              </Button>
            )}
            <Grid container spacing={3}>
              <HealthtestComponent
                resultType={resultType}
                data={initialResult}
                control={control}
                watch={watch}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="additionalInfo"
              control={control}
              defaultValue={initialResult?.additionalInfo || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Additional information"
                  error={errors?.additionalInfo}
                  helperText={
                    errors?.additionalInfo && errors?.additionalInfo?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default HealthtestForm;
