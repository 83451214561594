import React from "react";
import { useParams } from "react-router-dom";

// Recoil
import { useRecoilValue } from "recoil";
import { adminPendingDogsState } from "recoil/adminStates";

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { GetDogColumns } from "components/adminDashboardComponents/adminDatagridColumns";

export default function PendingDogsWidget() {
  const { organizationPrefix } = useParams();
  const pendingDogs = useRecoilValue(adminPendingDogsState);
  const handleRowClick = (params) => {
    window.location = `/${organizationPrefix}/admin/dog/${params.row._id}`;
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />} id="pendingDogs">
          <Typography variant="h3">
            Pending dog registrations
            <Chip
              color="primary"
              size="small"
              label={pendingDogs.length}
              sx={{ ml: 2 }}
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={pendingDogs}
            getRowId={(row) => row._id}
            columns={GetDogColumns("dog")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}
