import { selector, selectorFamily } from "recoil";
import {
  getUsersLitters,
  getUsersPendingDogs,
  getUsersRegisteredDogs,
  getUsersPendingLitters,
  getVerification,
} from "api/privateRoutes";
import { accessTokenState } from "./globalStates";

export const usersDogsState = selector({
  key: "usersDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersRegisteredDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

export const usersPendingDogsState = selector({
  key: "usersPendingDogsState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersPendingDogs({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

export const usersBredLittersState = selector({
  key: "usersBredLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

export const usersPendingLittersState = selector({
  key: "usersPendingLittersState",
  get: async ({ get }) => {
    const accessToken = get(accessTokenState);
    const response = await getUsersPendingLitters({ accessToken });
    if (response.error) {
      throw response;
    }
    return response;
  },
});

export const verificationByIdState = selectorFamily({
  key: "verificationByIdState",
  get:
    (_id) =>
    async ({ get }) => {
      const accessToken = get(accessTokenState);

      const response = await getVerification(_id, { accessToken });
      if (response.error) {
        throw response;
      }
      return response;
    },
});
