import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// Recoil
import {
  useRecoilState,
  useResetRecoilState,
  useRecoilValue,
  useSetRecoilState } from "recoil";
import {
  accessTokenState,
  loadingState,
  snackBarState,
  userState,
} from "recoil/globalStates";
import { getOrgByPrefixState } from "recoil/publicStates";

import { litterRegistrationState } from "recoil/registrationStates";

import {
  Alert,
  AlertTitle,
  Button,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";

import { sendLitterRegDataToApi } from "components/registrationComponents/constructObject";
import { getPaymentAmount, compareRole } from "constants/helpers";
import LitterRegistration from "./litterRegistration";

export default function LitterRegistrationContainer() {
  const { t } = useTranslation();
  const { organizationPrefix } = useParams();
  const org = useRecoilValue(getOrgByPrefixState(organizationPrefix));
  const setSnackbarState = useSetRecoilState(snackBarState);
  const setLoading = useSetRecoilState(loadingState);

  const [formData, setFormData] = useRecoilState(litterRegistrationState);
  const resetFormData = useResetRecoilState(litterRegistrationState);
  const activeStep = formData.activeStep;

  const user = useRecoilValue(userState);
  const accessToken = useRecoilValue(accessTokenState);

  const methods = useForm({ mode: "all" });
  const {
    handleSubmit,
    reset,
    trigger,
    formState: { errors },
  } = methods;

  const isErrorsEmpty = Object.keys(errors).length === 0;

  const handleClearForm = () => {
    resetFormData();
    reset();
  };

  const setActiveStep = (step) => {
    setFormData((formData) => ({
      ...formData,
      ["activeStep"]: step,
    }));
  };

  // Increase activestep to show next step, use trigger to validate before proceeding
  const handleNext = async () => {
    await trigger().then((res) => {
      if (res === true) {
        setActiveStep(activeStep + 1);
      }
    }
  );
  };    

  // Decrease activestep to show previous step
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // Handle form data formatting and sending to APIs
  const onSubmit = async (e) => {
    e.preventDefault();
  
    if (formData.puppies.length === 0) {
      setError("puppies", {
        type: "manual",
        message: "At least one puppy must be added."
      });
      return;
    }
  
    // Prepare the updated form data
    let updatedFormData = { ...formData };
  
    // Submit list of IDs from declaredTypes
    const declaredTypeObjs = [formData.declaredBreed, formData.declaredMix, formData.declaredType1, formData.declaredType2];
    const declaredTypeIds = declaredTypeObjs.filter(Boolean).map((type) => type._id);
    if (declaredTypeIds.length !== 0) {
      updatedFormData.declaredTypes = declaredTypeIds;
    }
  
    // Convert dateOfBirth to string if it's an object
    if (formData.dateOfBirth && typeof formData.dateOfBirth === 'object') {
      updatedFormData.dateOfBirth = dayjs(formData.dateOfBirth).format('MM/DD/YYYY');
    }
  
    // Set organization/registry
    updatedFormData.registry = org;
  
    // Update the form data state
    setFormData(updatedFormData);
  
    // Proceed with form submission
    await trigger().then((res) => {
      if (res === true) {
        setLoading(true);
        sendLitterRegDataToApi(updatedFormData, user, accessToken).then((res) => {
          if (res) {
            setSnackbarState({
              message: res.message,
              severity: "error",
              open: true,
            });
          }
          setLoading(false);
        });
      }
    });
  };
  
  let amount = 0;
  if (activeStep === 2) {
    amount = getPaymentAmount(user, "litter", formData.registry);
  }

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Paper sx={{ p: { xs: 2, sm: 4 } }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stack
                  display="flex"
                  justifyContent="space-between"
                  sx={{ flexDirection: { xs: "column", sm: "row" } }}
                >
                  <Typography variant="h1" sx={{ mb: 3 }}>
                    {t("litterRegistration")}
                    {user.role === "basic" || " (" + t(user.role) + ")"}
                  </Typography>
                  <Typography
                    variant="body"
                    sx={{ mr: 2, mt: 1, float: "right" }}
                  >
                    {t("mandatoryFields")}{" "}
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <LitterRegistration activeStep={activeStep} errors={errors} />
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    {t("previous")}
                  </Button>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Button onClick={handleClearForm}>{t("Clear")}</Button>
                  <Box>
                    {!isErrorsEmpty && (
                      <Typography variant="caption" color="error">
                        {t("errorCheckInput")}
                      </Typography>
                    )}

                    {activeStep === 2 ? (
                      <Button
                        onClick={onSubmit}
                        disabled={
                          !compareRole(user.role, "manager") &&
                          (!formData.dataVerified ||
                            !formData.orgAgreement ||
                            !formData.platformAgreement)
                         }
                      >
                        {amount > 0 ? t("proceedToPay") : t("register")}
                      </Button>
                    ) : (
                      <Button onClick={handleNext}>{t("next")}</Button>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </FormProvider>
    </>
  );
}
