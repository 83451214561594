import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { accessTokenState, snackBarState, userState } from "recoil/globalStates";
import { adminAllOrgsState } from "recoil/adminStates";
import { adminAllUsersState } from "recoil/adminStates";

// MUI
import {
  Autocomplete,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Local
import {
  createNewOrganization,
  deleteOrganization,
  updateOrganization,
} from "api/adminRoutes";

import { compareRole } from "constants/helpers";

import { GetUserColumns } from "components/adminDashboardComponents/adminDatagridColumns";
import JsonPreviewWidget from "components/adminDashboardComponents/jsonPreviewWidget";
import FormHeader from "components/adminDashboardComponents/formHeader";
import FormButtons from "components/adminDashboardComponents/formButtons";

function OrganizationForm({ action, org, handleModalClose }) {
  const { organizationPrefix } = useParams();
  const { t } = useTranslation();
  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);
  const user = useRecoilValue(userState);

  const organizations = useRecoilValue(adminAllOrgsState);

  const [initialOrg, setInitialOrg] = useState(org);

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const handleRowClick = (params) => {
    window.location = `/admin/user/${params.row._id}`;
  };

  // Get all users and select the ones we want.
  // I believe this is a savings because they're in recoil
  // (if we did an "only this org" query we'd have to go to the DB).
  const users = useRecoilValue(adminAllUsersState);
  const orgUsers = initialOrg ? users.filter((user) =>
    user.organizations.some((org) => org.organization._id === initialOrg._id)
  ) : [];
  const onSubmit = (data) => {
    if (action === "add") {
      createNewOrganization(data, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("creationSuccess", { docType: "Organization" }),
            severity: "success",
            open: true,
          });
        }
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
    if (action === "edit") {
      updateOrganization(initialOrg._id, data, { accessToken }).then((res) => {
        if (res.error) {
          setSnackbarState({
            message: res.message,
            severity: "error",
            open: true,
          });
        } else {
          setSnackbarState({
            message: t("updateSuccess", { docType: "Organization" }),
            severity: "success",
            open: true,
          });
        }
      });
      setTimeout(() => {
        window.location.reload(true);
      }, 1000);
    }
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this organization? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteOrganization(initialOrg._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Organization has been removed.`);
              setTimeout((window.location = `/admin/allorganizations`), 3000);
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="organization"
              data={initialOrg}
              handleModalClose={handleModalClose}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="name"
              control={control}
              defaultValue={initialOrg?.name || ""}
              rules={{
                minLength: 1,
                required: "Required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  label={t("Name of organization")}
                  error={errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="prefix"
              control={control}
              defaultValue={initialOrg?.prefix || ""}
              rules={{
                minLength: 1,
                required: "Required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  required
                  fullWidth
                  label={t("Short prefix")}
                  error={errors?.prefix}
                  helperText={errors?.prefix && errors?.prefix?.message}
                />
              )}
            />
          </Grid>
          
          { /*
          <Grid item xs={12} md={6}>
            <Controller
              name="logo"
              control={control}
              defaultValue={initialOrg?.name || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label={t("Logo")}
                  error={errors?.name}
                  helperText={errors?.name && errors?.name?.message}
                />
              )}
            />
          </Grid> */ }
          
          <Grid item xs={12} md={6}>
            <Controller
              name="url"
              defaultValue={initialOrg?.url || ""}
              control={control}
              rules={{
                minLength: 1,
                required: "Required",
              }}
              render={({ field: { ref, name, ...field } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Organization URL"
                  helperText={t("Include https:// in the url")}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Controller
              name="rulesurl"
              defaultValue={initialOrg?.rulesurl || ""}
              control={control}
              rules={{
                minLength: 1,
                required: "Required",
              }}
              render={({ field: { ref, name, ...field } }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Rules URL"
                  helperText={t("Include https:// in the url")}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel>Type *</InputLabel>
              <Controller
                name="type"
                control={control}
                defaultValue={initialOrg?.type || ""}
                rules={{
                  minLength: 1,
                  required: "Required",
                }}
                render={({ field: { ref, name, ...field } }) => (
                  <Select {...field} fullWidth required label="Type">
                    <MenuItem key={0} value="member">
                      Member
                    </MenuItem>
                    <MenuItem key={1} value="partner">
                      Partner
                    </MenuItem>
                  </Select>
                )}
              />
              <FormHelperText>{errors?.type?.message}</FormHelperText>
            </FormControl>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Controller
              name="parentOrg"
              onChange={([, data]) => data}
              defaultValue={initialOrg?.parentOrg || null}
              control={control}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  label="Parent organization"
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                  options={organizations}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <TextField {...params} label="Parent organization" />
                  )}
                  {...props}
                />
              )}
            />
          </Grid>
          

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">{t("Pricing")}</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="pricing.dog.breeder"
              control={control}
              defaultValue={initialOrg?.pricing?.dog?.breeder || "0"}
              min="0"
              rules={{
                required: "Required",
                min: 0,
                valueAsNumber: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  error={errors?.pricing?.dog?.breeder}
                  label={t("Dog registration (breeder)")}
                  helperText={"USD"}
                  disabled={!compareRole(user.role, "admin")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="pricing.dog.manager"
              control={control}
              defaultValue={initialOrg?.pricing?.dog?.manager || "0"}
              min="0"
              rules={{
                required: "Required",
                min: 0,
                valueAsNumber: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  error={errors?.pricing?.dog?.manager}
                  label={t("Dog registration (manager)")}
                  helperText={"USD"}
                  disabled={!compareRole(user.role, "admin")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="pricing.litter.breeder"
              control={control}
              defaultValue={initialOrg?.pricing?.litter?.breeder || "0"}
              min="0"
              rules={{
                required: "Required",
                min: 0,
                valueAsNumber: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  error={errors?.pricing?.litter?.breeder}
                  label={t("Litter registration (breeder)")}
                  helperText={"USD"}
                  disabled={!compareRole(user.role, "admin")}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="pricing.litter.manager"
              control={control}
              defaultValue={initialOrg?.pricing?.litter?.manager || "0"}
              min="0"
              rules={{
                required: "Required",
                min: 0,
                valueAsNumber: true,
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  error={errors?.pricing?.litter?.manager}
                  label={t("Litter registration (manager)")}
                  helperText={"USD"}
                  disabled={!compareRole(user.role, "admin")}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider textAlign="left" sx={{ my: 2 }}>
              <Typography variant="h5">{t("Users")}</Typography>
            </Divider>
          </Grid>
          <Grid item xs={12}>
            <DataGrid
              autoHeight
              pageSize={50}
              onRowClick={handleRowClick}
              rows={orgUsers}
              getRowId={(row) => row._id}
              columns={GetUserColumns("user")}
              components={{ Toolbar: GridToolbar }}
              disableSelectionOnClick
            />
          </Grid>

          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default OrganizationForm;
