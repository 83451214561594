import React from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

// Recoil
import { useRecoilValue } from "recoil";
import { kruValuesState } from "recoil/globalStates";
import { adminAllOrgsState } from "recoil/adminStates";

// MUI
import { IconButton, Link, Tooltip, Typography } from "@mui/material";
import { Launch } from "@mui/icons-material";

// Local
import { isFormatSidesOrGrade } from "constants/healthtestFormat";

const dateFormatter = ({ value }) =>
  (value && dayjs(value).format("MM.DD.YYYY")) || "";

const parentFormatter = ({ value }) =>
  (value && `${value.name} ${value.regNumber ? "("+value.regNumber+")" : ""}`);

function getVerifications(params) {
  if (params.value == null || params.value.length === 0 ) {
    return "No verifications";
  } else {
    const verified = params.value.filter((val) => val.verified === true);
    const notVerified = params.value.filter((val) => val.verified === false);

    return `Verified: ${verified.length}, not verified: ${notVerified.length}`;
  }
}

export function GetDogColumns(url) {
  const { organizationPrefix } = useParams();
  const kruValues = useRecoilValue(kruValuesState);

  const dogColumns = [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "regNumber",
      headerName: "Reg.no",
      minWidth: 120,
    },
    {
      field: "microchipNumber",
      headerName: "Microchip.no",
      minWidth: 180,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 270,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}
        >
          <Tooltip
            placement="right"
            title={
              <React.Fragment>
                <Typography variant="body2">{params.value}</Typography>
              </React.Fragment>
            }
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "dateOfBirth",
      headerName: "DOB",
      minWidth: 100,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = dayjs(params.value).format("MM.DD.YYYY");
        return formattedValue;
      },
    },
    {
      field: "isDeceased",
      type: "boolean",
      headerName: "Deceased",
    },
    {
      field: "sex",
      headerName: "Gender",
    },
    {
      field: "regType",
      headerName: "Reg.type",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const found = kruValues.find(
          (kruvalue) => kruvalue._id == params.value
        );
        const mappedValues = found?.en?.name;

        return mappedValues;
      },
    },
    {
      field: "regClass",
      headerName: "Reg.class",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const found = kruValues.find(
          (kruvalue) => kruvalue._id == params.value
        );
        const mappedValues = found?.en?.name;

        return mappedValues;
      },
    },
    {
      field: "organizations",
      headerName: "Organizations",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params.value.map((value) =>
          value.organization?.name ? value.organization?.name : ""
        );

        return mappedValues.join(", ");
      },
    },
    {
      field: "certificates",
      headerName: "Certificates",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params.value.map((value) =>
          value.certificate?.name ? value.certificate.name : ""
        );

        return mappedValues.join(", ");
      },
    },
    {
      field: "litter",
      headerName: "Litter",
      minWidth: 180,
    },
    {
      field: "sire",
      headerName: "Sire",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const formattedValue =
          params.value.name + " (" + params.value.regNumber + ")";

        return formattedValue;
      },
    },
    {
      field: "dam",
      headerName: "Dam",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const formattedValue =
          params.value.name + " (" + params.value.regNumber + ")";

        return formattedValue;
      },
    },
    {
      field: "breeder",
      headerName: "Breeder",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = params.value.name;
        return formattedValue;
      },
    },
    {
      field: "owners",
      headerName: "Owners",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        /* 
        const mappedValues = params.value.map((owner) => ` ${owner.name}`);
        const formattedValue = params.value.length + " - " + mappedValues; */
        return params.value.length;
      },
    },
    {
      field: "color",
      headerName: "Color",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params.value.map((value) => {
          let foundColor = kruValues.find((color) => color._id == value);
          if (!foundColor) {
            return "";
          }

          return foundColor?.en?.name;
        });

        return mappedValues.join(", ");
      },
    },
    {
      field: "coat",
      headerName: "Coat",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params.value.map((value) => {
          let foundCoat = kruValues.find((coat) => coat._id == value);
          if (!foundCoat) {
            return "";
          }
          return foundCoat?.en?.name;
        });

        return mappedValues.join(", ");
      },
    },
    {
      field: "countryOfOrigin",
      headerName: "Origin country",
      minWidth: 150,
    },
    {
      field: "regOther",
      headerName: "Reg.others",
      minWidth: 150,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params.value.map((value) => {
          const joined = Object.values(value).join(" - ");

          return joined;
        });

        return mappedValues.join(", ");
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = dayjs(params.value).format("MM.DD.YYYY");
        return formattedValue;
      },
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = dayjs(params.value).format("MM.DD.YYYY");
        return formattedValue;
      },
    },
  ];

  return dogColumns;
}

export function GetTreedogColumns(url) {
  const { organizationPrefix } = useParams();
  const dogColumns = [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "regNumber",
      headerName: "Reg.no",
      minWidth: 120,
    },
    {
      field: "microchipNumber",
      headerName: "Microchip.no",
      minWidth: 180,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 270,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}
        >
          <Tooltip
            placement="right"
            title={
              <React.Fragment>
                <Typography variant="body2">{params.value}</Typography>
              </React.Fragment>
            }
          >
            <Typography
              variant="body2"
              style={{
                fontWeight: 600,
              }}
            >
              {params.value}
            </Typography>
          </Tooltip>
        </Link>
      ),
    },
    {
      field: "sire",
      headerName: "Sire",
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const formattedValue =
          params.value.name + " (" + params.value.regNumber + ")";

        return formattedValue;
      },
    },
    {
      field: "dam",
      headerName: "Dam",
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        const formattedValue =
          params.value.name + " (" + params.value.regNumber + ")";

        return formattedValue;
      },
    },
    {
      field: "registry",
      headerName: "Registry",
      minWidth: 300,
    },
    {
      field: "additionalInfo",
      headerName: "Additional info",
      minWidth: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = dayjs(params.value).format("MM.DD.YYYY");
        return formattedValue;
      },
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = dayjs(params.value).format("MM.DD.YYYY");
        return formattedValue;
      },
    },
  ];

  return dogColumns;
}

export function GetUserColumns(url) {
  const { organizationPrefix } = useParams();
  const allOrganizations = useRecoilValue(adminAllOrgsState);

  const userColumns = [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "name",
      headerName: "Name",
      minWidth: 240,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
            }}
          >
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 240,
    },

    {
      field: "permissions",
      headerName: "Public permissions",
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue =
          "Name: " +
          params.value.name +
          ", City: " +
          params.value.city +
          ", Country: " +
          params.value.country;
        return formattedValue;
      },
    },
    {
      field: "role",
      headerName: "Role",
    },
    {
      field: "organizations",
      headerName: "Organizations",
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }

        // Populate organizations
        let mappedValues = [];
        params.value.forEach((org) => {
          if (org && org.organization) {
            const orgObj = allOrganizations.find(
              (o) => o._id === org.organization._id
            );
            mappedValues.push(orgObj?.name + " (" + orgObj?.role + ")");
         } else {
          mappedValues.push("Organization not found");
         }});
        return mappedValues.join(", ");
      },
    },
    {
      field: "certificates",
      headerName: "Certificates",
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const mappedValues = params.value.map((value) =>
          value.certificate.name ? value.certificate.name : ""
        );

        return mappedValues.join(", ");
      },
    },

    {
      field: "hasLitters",
      type: "boolean",
      headerName: "hasLitters",
    },
    {
      field: "breedingPrefix",
      headerName: "Prefix",
      minWidth: 180,
    },
    {
      field: "breederId",
      headerName: "Breeder ID",
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 200,
    },
    {
      field: "postalCode",
      headerName: "Postal code",
    },
    {
      field: "country",
      headerName: "Country code",
    },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 180,
    },

    {
      field: "updatedAt",
      headerName: "Updated",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = dayjs(params.value).format("MM.DD.YYYY");
        return formattedValue;
      },
    },
    {
      field: "createdAt",
      type: "date",
      headerName: "Created",
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        const formattedValue = dayjs(params.value).format("MM.DD.YYYY");
        return formattedValue;
      },
    },
  ];

  return userColumns;
}

export function getLitterColumns(url) {
  const { organizationPrefix } = useParams();
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "dateOfBirth",
      headerName: "DOB",
      minWidth: 100,
      valueFormatter: dateFormatter,
    },
    {
      field: "puppies",
      headerName: "Puppies",
      minWidth: 80,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.length;
        }
      },
    },
    {
      headerName: "Litter",
      minWidth: 80,
      renderCell: (params) => (
        <Link href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}>
          <IconButton>
            <Launch />
          </IconButton>
        </Link>
      ),
    },
    {
      field: "sire",
      headerName: "Sire",
      minWidth: 180,
      valueFormatter: parentFormatter,
    },
    {
      field: "dam",
      headerName: "Dam",
      minWidth: 180,
      valueFormatter: parentFormatter,
    },
    {
      field: "breeder",
      headerName: "Breeder",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.name;
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}

export function getPendingLitterColumns(url) {
  const { organizationPrefix } = useParams();
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "registrationStatus",
      headerName: "Reg. status",
      minWidth: 100,
    },
    {
      headerName: "Litter",
      minWidth: 80,
      renderCell: (params) => (
        <Link href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}>
          <IconButton>
            <Launch />
          </IconButton>
        </Link>
      ),
    },
    {
      field: "verifications",
      headerName: "Verifications",
      minWidth: 200,
      valueGetter: getVerifications,
    },
    {
      field: "dateOfBirth",
      headerName: "DOB",
      minWidth: 100,
      valueFormatter: dateFormatter,
    },
    {
      field: "puppies",
      headerName: "Puppies",
      minWidth: 80,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.length;
        }
      },
    },
    {
      field: "sire",
      headerName: "Sire",
      minWidth: 180,
      valueFormatter: parentFormatter,
    },
    {
      field: "dam",
      headerName: "Dam",
      minWidth: 180,
      valueFormatter: parentFormatter,
    },
    {
      field: "breeder",
      headerName: "Breeder",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.name;
        }
      },
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}

export function getGeneticTestColumns(url) {
  const { organizationPrefix } = useParams();
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Result",
      maxWidth: 70,
      renderCell: (params) => (
        <Link href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}>
          <IconButton>
            <Launch />
          </IconButton>
        </Link>
      ),
    },
    {
      field: "parentDoc",
      headerName: "Dog",
      minWidth: 300,
      valueFormatter: parentFormatter,
    },
    {
      field: "results",
      headerName: "Result count",
      minWidth: 180,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.length;
        }
      },
    },
    {
      field: "source",
      headerName: "Source",
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.name;
        }
      },
    },
    {
      field: "samplingVerified",
      type: "boolean",
      headerName: "Sampling verified",
      minWidth: 180,
    },
    {
      field: "additionalInfo",
      headerName: "Additional Info",
      minWidth: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}

export function getHealthTestColumns(url) {
  const { organizationPrefix } = useParams();
  const kruValues = useRecoilValue(kruValuesState);
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Result",
      maxWidth: 70,
      renderCell: (params) => (
        <Link href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}>
          <IconButton>
            <Launch />
          </IconButton>
        </Link>
      ),
    },
    {
      field: "parentDoc",
      headerName: "Dog",
      minWidth: 300,
      valueFormatter: parentFormatter,
    },
    {
      field: "subject",
      headerName: "Subject",
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          let foundItem = kruValues.find((item) => item._id == params.value);
          if (foundItem) {
            return `${foundItem.en.name}`;
          } else {
            return params.value;
          }
        }
      },
    },
    {
      field: "result",
      headerName: "Result",
      minWidth: 200,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          const isSidesOrGrades = isFormatSidesOrGrade(params.value.type);
          if (isSidesOrGrades === "twoSides") {
            return params.value.left + "/" + params.value.right;
          } else if (isSidesOrGrades === "grade") {
            return params.value.grade;
          } else if (isSidesOrGrades === "ecvo") {
            const resultValues = Object.values(params.value);
            const conditions = [
              "affected",
              "suspicious",
              "undetermined",
              "undetermined/suspicious",
            ];
            // check if any result has value specified above
            const isAffected = conditions.some((cond) =>
              resultValues.includes(cond)
            );

            if (isAffected) {
              // if ecvo result has any result with value that corresponds
              // to one of the "conditions" above, count the values
              const noOfAffected = resultValues.filter(
                (val) => val === "affected"
              ).length;
              const noOfSuspicious = resultValues.filter(
                (val) => val === "suspicious"
              ).length;
              const noOfUndeteremined = resultValues.filter(
                (val) => val === "undetermined"
              ).length;
              const noOfOtherSus = resultValues.filter(
                (val) => val === "undetermined/suspicious"
              ).length;
              return `${noOfAffected} affected, ${
                noOfSuspicious + noOfUndeteremined
              } suspicious/undetermined, ${noOfOtherSus} other`;
            } else {
              // if all result are "unaffected", return string "all clear"
              return "all clear";
            }
          } else {
            return params.value;
          }
        }
      },
    },
    {
      field: "source",
      headerName: "Source",
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.name;
        }
      },
    },
    {
      field: "additionalInfo",
      headerName: "Additional Info",
      minWidth: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}

export function getCompetitionResultsColumns(url) {
  const { organizationPrefix } = useParams();
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "date",
      headerName: "Date",
      minWidth: 100,
      valueFormatter: dateFormatter,
    },
    {
      headerName: "Result",
      maxWidth: 70,
      renderCell: (params) => (
        <Link href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}>
          <IconButton>
            <Launch />
          </IconButton>
        </Link>
      ),
    },
    {
      field: "parentDoc",
      headerName: "Dog",
      minWidth: 300,
      valueFormatter: parentFormatter,
    },
    {
      field: "sport",
      headerName: "Sport",
      minWidth: 300,
    },
    {
      field: "class",
      headerName: "Class",
      minWidth: 300,
    },
    {
      field: "result",
      headerName: "Result",
      minWidth: 200,
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 200,
    },
    {
      field: "judge",
      headerName: "Judge",
      minWidth: 300,
    },
    {
      field: "additionalInfo",
      headerName: "Additional Info",
      minWidth: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}
export function getOrganizationColumns(url) {
  const { organizationPrefix } = useParams();
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
            }}
          >
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 300,
    },
    {
      field: "parentOrg",
      headerName: "Parent org.",
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.name;
        }
      },
    },
    {
      field: "url",
      headerName: "URL",
      minWidth: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}
export function getResultproviderColumns(url) {
  const { organizationPrefix } = useParams();
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
            }}
          >
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: "url",
      headerName: "URL",
      minWidth: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}
export function getCertificatesColumns(url) {
  const { organizationPrefix } = useParams();
  return [
    { field: "_id", headerName: "ID", hide: true },
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      renderCell: (params) => (
        <Link
          style={{
            textDecoration: "none",
            color: "#004569",
          }}
          href={`/${organizationPrefix}/admin/${url}/${params.row._id}`}
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: 600,
            }}
          >
            {params.value}
          </Typography>
        </Link>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 300,
    },
    {
      field: "issuerOrg",
      headerName: "Issuer org.",
      minWidth: 300,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        } else {
          return params.value.name;
        }
      },
    },
    {
      field: "url",
      headerName: "URL",
      minWidth: 300,
    },
    {
      field: "updatedAt",
      headerName: "Updated",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
    {
      field: "createdAt",
      headerName: "Created",
      minWidth: 140,
      valueFormatter: dateFormatter,
    },
  ];
}
