import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { attachmentsModState } from "recoil/adminStates";
import { accessTokenState } from "recoil/globalStates";
import { dogRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faEye, faDeleteLeft, faDownload, faGlasses } from "@fortawesome/free-solid-svg-icons";

// Routes
// import { getAttachments } from "api/adminRoutes";
import { getAttachments } from "api/publicRoutes";

// Local
import { adminUrl, privateUrl, request } from "api/common";
import { imageServer } from "constants/values";

const iconStyle = {
  color: "#0000008a",
  fontSize: "1.2rem",
};

const useAttachmentHandlers = () => {
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);
  const [formData, setFormData] = useRecoilState(dogRegistrationState);
  const accessToken = useRecoilValue(accessTokenState);
  const { t } = useTranslation();
  const filepaths = formData.filePaths;

  const handleAttachmentView = (attachment) => {
    const url = adminUrl([`/attachments/byId/${attachment.get("id")}/file`]);
    request({ url, method: "GET", accessToken, raw: true })
      .then((response) => response.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        const fakeLink = document.createElement("a");
        fakeLink.href = file;
        fakeLink.target = "_blank";
        document.body.appendChild(fakeLink);
        fakeLink.click();
        window.URL.revokeObjectURL(file);
        document.body.removeChild(fakeLink);
      })
      .catch((error) => {
        console.error("Error fetching or processing attachment:", error);
      });
  };

  const handleAttachmentDownload = (attachment) => {
    const url = adminUrl([`/attachments/byId/${attachment.get("id")}/file`]);
    request({ url, method: "GET", accessToken, raw: true })
      .then((response) => response.blob())
      .then((blob) => {
        const file = window.URL.createObjectURL(blob);
        const filename = `${attachment.get("category")}-${attachment.get("parentDoc")}-${attachment.get("originalName")}`;
        const fakeLink = document.createElement("a");
        fakeLink.href = file;
        fakeLink.download = filename;
        document.body.appendChild(fakeLink);
        fakeLink.click();
        document.body.removeChild(fakeLink);
        window.URL.revokeObjectURL(file);
      })
      .catch((error) => {
        console.error("Error downloading attachment:", error);
      });
  };

  const handleAttachmentParse = (attachment) => {
    const url = privateUrl([`/attachments/parseById/${attachment.get("id")}`]);
    request({ url, method: "GET", accessToken, raw: true })
      .then((response) => response.json())
      .then((json) => {
        console.log("Parsed attachment:", json);
      })
      .catch((error) => {
        console.error("Error parsing attachment:", error);
      });
  };

  const handleAttachmentAdd = (category) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    fileInput.accept = "image/*,.pdf";
    fileInput.name = category;
    document.body.appendChild(fileInput);
  
    const cleanUp = () => document.body.removeChild(fileInput);
  
    fileInput.onchange = (event) => {
      const { name, files } = event.target;
      let newAttachments = [];
      for (const file of files) {
        const formData = new FormData();
        formData.set("add", true);
        formData.set("attachment", file);
        formData.set("category", category);
        formData.set("originalName", file.name);
        formData.set("dateAdded", new Date());
  
        // Check if category is profileImage and if it's the only one
        if (category === "profileImage") {
          const profileImageAttachments = attachmentsMod.filter(a => a.get("category") === "profileImage");
          if (profileImageAttachments.length === 0) {
            formData.set("primary", "true");
          }
        }
  
        newAttachments.push(formData);
      }
      setAttachmentsMod([...attachmentsMod, ...newAttachments]);
      const path = !files.length
        ? t("noFileSelected")
        : files.length === 1
          ? `${files[0].name}`
          : `${files.length} ${t("filesSelected")}`;
      setFormData({ ...formData, filePaths: { ...filepaths, [name]: path } });
      cleanUp();
    };
  
    fileInput.addEventListener('blur', cleanUp, { once: true });
    fileInput.click();
  };

  const handleAttachmentDelete = (attachment) => {
    setAttachmentsMod(attachmentsMod.filter((a) => {
      if (a.get("id") === attachment.get("id")) {

        // If the attachment is marked for addition, remove it from the list
        if (a.get("add") === "true") {
          // New attachments have no ID. If there are several, we need to be able to
          // distinguish between them, so we use the dateAdded field as a unique identifier.
          // Copilot wants us to use the original filename, but that's not necessarily unique.
          if (a.get("dateAdded") === attachment.get("dateAdded")) {
            return false; // Exclude this attachment from the new array
          }
          return true;
        }

        // If the attachment is marked for deletion, unmark it
        if (a.get("delete") === "true") {
          a.set("delete", "false");
          return true; // Include this attachment in the new array
        }
        a.set("delete", "true");
      }
      return true; // Include all other attachments in the new array
    }));
  };

  return {
    handleAttachmentView,
    handleAttachmentDownload,
    handleAttachmentParse,
    handleAttachmentAdd,
    handleAttachmentDelete,
  };
};

/* Components */

function AdminProfileImageList( { attachments }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mainColor = theme.palette.primary.main;

  const {
    handleAttachmentView,
    handleAttachmentDownload,
    handleAttachmentDelete
  } = useAttachmentHandlers();

  const { control } = useForm();
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);
  
  // Ensure there is a primary profile image set.
  if (attachments.length > 0) {
    const primaryImageAttachments = attachments.filter(a => a.get("primary") === "true");
    if (primaryImageAttachments.length === 0) {
      attachments[0].set("primary", "true");
  }}

  const handlePrimaryImageChange = (attachment) => {
    if (attachment.get("category") !== "profileImage") return;

    const profileImageAttachments = attachmentsMod.filter(a => a.get("category") === "profileImage");

    if (profileImageAttachments.length === 1) {
      profileImageAttachments[0].set("primary", "true");
    } else {
      const updatedAttachments = attachmentsMod.map((a) => {
        if (a.get("category") === "profileImage") {
          if (a.get("id") === attachment.get("id")) {
            a.set("primary", a.get("primary") === "true" ? "false" : "true");
          } else if (a.get("primary") === "true") {
            a.set("primary", "false");
          }
        }
        return a;
      });
      setAttachmentsMod(updatedAttachments);
    }
  };

  if (! attachments || attachments.length < 1) {
    return <Typography variant="body">No profile images yet.</Typography>;
  }

  return (
      <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>File name</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>Download</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment, i) => (
          <TableRow key={i}>
            <TableCell sx={{ width: "10%" }}>
              <Paper
                component="img"
                src={attachment.get("thumbnailUrl")}
                sx={{
                  width: "100%",
                  border: attachment.get("primary") === "true" ? `4px solid ${mainColor}` : "none"
                }}
                onClick={() => handlePrimaryImageChange(attachment)}
              />
            </TableCell>
            <TableCell>
                <span style={attachment.get("delete") === "true" ? { color: 'red', textDecoration: 'line-through' } : {}}>
                  {attachment.get("originalName")}
                </span>
              </TableCell>
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentView(attachment)}>
                    <FontAwesomeIcon icon={faEye} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentDownload(attachment)}>
                    <FontAwesomeIcon icon={faDownload} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                <IconButton onClick={() => handleAttachmentDelete(attachment)}>
                  <FontAwesomeIcon icon={faDeleteLeft} style={iconStyle} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AdminGeneticResultsList( { attachments }) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const {
    handleAttachmentView,
    handleAttachmentDownload,
    handleAttachmentDelete
  } = useAttachmentHandlers();

  if (! attachments || attachments.length < 1) {
    return <Typography variant="body">No genetic test results yet.</Typography>;
  }

  return (
      <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File name</TableCell>
            {/* <TableCell>Parse</TableCell> */}
            <TableCell>Preview</TableCell>
            <TableCell>Download</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment, i) => (
          <TableRow key={i}>
              <TableCell>
                <span style={attachment.get("delete") === "true" ? { color: 'red', textDecoration: 'line-through' } : {}}>
                  {attachment.get("originalName")}
                </span>
              </TableCell>
              {/* <TableCell>
                {attachment.get("mime") === "application/pdf"
                  && <IconButton
                  onClick={() =>
                    handleAttachmentParse(attachment)
                  }
                >
                  <FontAwesomeIcon icon={faGlasses} style={iconStyle} />
                </IconButton> }             
                </TableCell> */}
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentView(attachment)}>
                    <FontAwesomeIcon icon={faEye} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentDownload(attachment)}>
                    <FontAwesomeIcon icon={faDownload} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentDelete(attachment)}>
                    <FontAwesomeIcon icon={faDeleteLeft} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AdminMiscList( { attachments, categoryDesc }) {
  const { t } = useTranslation();

  const {
    handleAttachmentView,
    handleAttachmentDownload,
    handleAttachmentDelete
  } = useAttachmentHandlers();

  if (! attachments || attachments.length < 1) {
    return <Typography variant="body">{`No ${categoryDesc} yet.`}</Typography>;
  }

  return (
      <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>File name</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>Download</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {attachments.map((attachment, i) => (
          <TableRow key={i}>
              <TableCell>
                <span style={attachment.get("delete") === "true" ? { color: 'red', textDecoration: 'line-through' } : {}}>
                  {attachment.get("originalName")}
                </span>
              </TableCell>
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentView(attachment)}>
                    <FontAwesomeIcon icon={faEye} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentDownload(attachment)}>
                    <FontAwesomeIcon icon={faDownload} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
              <TableCell>
                {attachment.get("id") && (
                  <IconButton onClick={() => handleAttachmentDelete(attachment)}>
                    <FontAwesomeIcon icon={faDeleteLeft} style={iconStyle} />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function AdminAttachmentList({ parentDoc, accessToken }) {
  const parentId = parentDoc._id;

  const { handleAttachmentAdd } = useAttachmentHandlers();

  // TODO use admin interface again when we have health testing working
  // const accessToken = useRecoilValue(accessTokenState);

  const [loading, setLoading] = useState(true);
  const [attachmentsMod, setAttachmentsMod] = useRecoilState(attachmentsModState);

  const { t } = useTranslation();
    
  useEffect(() => {
    // getAttachments({ accessToken, query: { parentDoc: parentId } }).then(
    getAttachments({ query: { parentDoc: parentId } }).then(
      (data) => {
        setLoading(true);
        const primaryImage = parentDoc.primaryImage;
        let startAttachments = [];
        for (const d of data) {
          const formData = new FormData();
          formData.set(`id`, d._id);
          formData.set(`parentDoc`, d.parentDoc);
          formData.set(`category`, d.category);
          formData.set(`originalName`, d.originalName);
          formData.set(`mime`, d.mime);
          formData.set(`fileName`, d.fileName);
          formData.set(`path`, d.path);
          if (d.category === "profileImage") {
            formData.set(`thumbnailUrl`, imageServer + "/" + d.alternates.thumbnail.path + "/" + d.fileName);
            if (d.fileName === primaryImage) {
              formData.set(`primary`, "true");
            }
          }
          startAttachments.push(formData);
        }

        setAttachmentsMod(startAttachments);
        setLoading(false);
      }
    );
  }, []);

  if (loading) return (<Typography variant="body">Loading attachments...</Typography>);
  
  const categorizedAttachments = {
    profileImage: [],
    geneticResult: [],
    pedigreeCert: [],
    misc: [],
  };

  attachmentsMod.forEach((attachment) => {
    const category = attachment.get("category");
    categorizedAttachments[category].push(attachment);
  });

  return (
    <>    
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Profile images</Typography>
          <IconButton
            onClick={() => handleAttachmentAdd("profileImage")} aria-label="add">
            <FontAwesomeIcon icon={faAdd} style={iconStyle} />
          </IconButton>
        </Box>
        <AdminProfileImageList attachments={categorizedAttachments.profileImage} />
      </Grid>
    
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">{t("Genetic test results")}</Typography>
          <IconButton
            onClick={() => handleAttachmentAdd("geneticResult")} aria-label="add">
            <FontAwesomeIcon icon={faAdd} style={iconStyle} />
          </IconButton>
        </Box>
        <AdminGeneticResultsList attachments={categorizedAttachments.geneticResult} />
      </Grid>
    
      <Grid item xs={12}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5">Pedigree certificate(s)</Typography>
          <IconButton
            onClick={() => handleAttachmentAdd("pedigreeCert")} aria-label="add">
            <FontAwesomeIcon icon={faAdd} style={iconStyle} />
          </IconButton>
        </Box>
        <AdminMiscList attachments={categorizedAttachments.pedigreeCert} categoryDesc="pedigree certificates" />
      </Grid>
    
      <Grid item xs={12}>
      <Box display="flex" alignItems="center">
          <Typography variant="h5">Miscellaneous</Typography>
          <IconButton
            onClick={() => handleAttachmentAdd("misc")} aria-label="add">
            <FontAwesomeIcon icon={faAdd} style={iconStyle} />
          </IconButton>
        </Box>
        <AdminMiscList attachments={categorizedAttachments.misc} categoryDesc="miscellaneous attachments" />
      </Grid>
    </Grid>
    
    {/*
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>File name</TableCell>
            <TableCell>Preview</TableCell>
            <TableCell>Download</TableCell>
            <TableCell>Parse</TableCell>
            <TableCell>Add</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {Object.entries(categorizedAttachments).map(([category, attachments]) =>
          processAttachments(attachments, category).map((attachment, i) => (
            <TableRow key={i}>
              <TableCell>{attachment.get("category")}</TableCell>
              <TableCell>
                <span style={attachment.get("delete") === "true" ? { color: 'red', textDecoration: 'line-through' } : {}}>
                  {attachment.get("originalName")}
                </span>
              </TableCell>
              <TableCell>
                {attachment.get("id") && <IconButton
                  onClick={() =>
                    handleAttachmentView(attachment)
                  }
                >
                  <FontAwesomeIcon icon={faEye} style={iconStyle} />
                </IconButton> }
              </TableCell>
              <TableCell>
                { attachment.get("id") && <IconButton
                  onClick={() =>
                    handleAttachmentDownload(attachment)
                  }
                >
                  <FontAwesomeIcon icon={faDownload} style={iconStyle} />
                </IconButton> }
              </TableCell>
              <TableCell>
                { attachment.get("id")
                  && attachment.get("category") === "misc"
                  && attachment.get("mime") === "application/pdf"
                  && <IconButton
                  onClick={() =>
                    handleAttachmentParse(attachment)
                  }
                >
                  <FontAwesomeIcon icon={faGlasses} style={iconStyle} />
                </IconButton> }
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() =>
                    handleAttachmentAdd(attachment.get("category"))
                  }
                >
                  <FontAwesomeIcon icon={faAdd} style={iconStyle} />
                </IconButton>
              </TableCell>
              <TableCell>
                { attachment.get("id") && <IconButton
                  onClick={() =>
                    handleAttachmentDelete(attachment)
                  }
                >
                  <FontAwesomeIcon icon={faDeleteLeft} style={iconStyle} />
                </IconButton> }
              </TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
    */}
    </>
  );
}
