import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { useRecoilValue } from "recoil";
import { dbStatsState } from "recoil/publicStates";

import { Link, ListItemButton, Paper, Typography, Grid, Button, Stack } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { userCanRegister } from "constants/helpers";


const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export default function OrganizationInfoBox( { org } ) {
  const { t } = useTranslation();
  const location = useLocation();
  const targetPath = `/${org.prefix}/home`;
  const stats = useRecoilValue(dbStatsState);

  return (
    <Paper sx={{ mb: 2, p: 3 }}>
      <Grid
        container
        sx={{ mb: 6 }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={9}>
          { /* This is where org logo would go
          <Box
            sx={{ py: 1, height: 100 }}
            component="img"
            src={FDCLogo}
            alt="{org.name} logo"
          />
          */ }
       <Typography variant="h1">
        {location.pathname === targetPath ? (
          org.name
        ) : (
          <StyledListButton href={targetPath} style={{ textDecoration: 'none' }}>
            {org.name}
          </StyledListButton>
        )}
      </Typography>
           <Typography variant="body1">
            {org.url && <Link href={org.url} style={{ textDecoration: 'none' }}>{org.url}</Link>}
          </Typography>
  
        </Grid>

        {userCanRegister(org) && (
          <Grid item xs={12} sm={3}>
            <div
              style={{
                textAlign: "right",
                marginTop: "10px",
                marginRight: "20px",
              }}
            >
              <Stack direction="column" spacing={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ChevronRight />}
                  href={`/${org.prefix}/dog-registration`}
                >
                  {t("registerDog")}
                </Button> 

                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<ChevronRight />}
                  href={`/${org.prefix}/litter-registration`}
                >
                  {t("registerLitter")}
                </Button>
              </Stack>
            </div>
          </Grid>
        )}
      </Grid>

      <Grid container>
        {/*<Grid item xs={6} sm={2}>
          <Typography component={Link} style={{ textDecoration: 'none' }} href={`/${org.prefix}/dogs`} variant="body2">{t("dogsRegistered")}</Typography>
          <Typography variant="h4">{stats.organizations[org._id].dog}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography component={Link} style={{ textDecoration: 'none' }} href={`/${org.prefix}/litters`} variant="body2">{t("littersRegistered")}</Typography>
          <Typography variant="h4">{stats.organizations[org._id].litter}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("healthTestResults")}</Typography>
          <Typography variant="h4">{stats.healthtest}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("geneticTestResults")}</Typography>
          <Typography variant="h4">{stats.geneticspanel}</Typography>
        </Grid>
        <Grid item xs={6} sm={2}>
          <Typography variant="body2">{t("competitionResults")}</Typography>
          <Typography variant="h4">{stats.competitionresult}</Typography>
          </Grid> */}
      </Grid>
    </Paper>
  );
}
