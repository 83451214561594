import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import {
  Button,
  Grid,
  TextField,
  Typography,
  Select,
  FormControl,
  MenuItem,
  Stack,
  Autocomplete,
  InputLabel,
  IconButton,
  FormGroup,
  FormControlLabel,
  Switch,
  FormHelperText,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { deleteGeneticResult } from "api/adminRoutes";

import DogAutocomplete from "components/formComponents/dogAutocomplete";

import { accessTokenState, kruValuesState } from "recoil/globalStates";
import { adminAllDogsState, adminDogByIdState } from "recoil/adminStates";
import { useRecoilValue } from "recoil";
import {
  allGeneticTestTypesState,
  allResultProvidersState,
} from "recoil/publicStates";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import FormHeader from "components/adminDashboardComponents/formHeader";
import JsonPreviewWidget from "components/adminDashboardComponents/jsonPreviewWidget";
import FormButtons from "components/adminDashboardComponents/formButtons";
import { Add, Delete } from "@mui/icons-material";

function GenetictestForm({
  action,
  result,
  emitHandleSubmit,
  handleModalClose,
}) {
  const { organizationPrefix } = useParams();
  const accessToken = useRecoilValue(accessTokenState);
  const kruValues = useRecoilValue(kruValuesState);

  const providers = useRecoilValue(allResultProvidersState);
  const testTypes = useRecoilValue(allGeneticTestTypesState);

  const dogs = useRecoilValue(adminAllDogsState);
  const [selectedDog, setSelectedDog] = useState(
    useRecoilValue(adminDogByIdState(result?.parentDoc))
  );

  const [initialResult, setInitialResult] = useState(result);

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "results",
  });

  const formdata = watch();
  const results = formdata.results;

  useEffect(() => {
    setValue("results", initialResult?.results);
  }, [initialResult]);

  // add new object to results array in the form state
  const addResult = () => {
    append({
      subject: null,
      result: "",
    });
  };

  const onSubmit = (data) => {
    // map each object as _id before sending it to API
    data.parentDoc = data.parentDoc._id;
    data.source = data.source._id;
    // set parentDocType, otherwise result's organizations won't be set
    data.parentDocType = "dog";

    emitHandleSubmit(accessToken, data, initialResult?._id);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this result? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteGeneticResult(initialResult._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Result has been removed.`);
              setTimeout((window.location = `/${organizationPrefix}/admin/allgenetictestresults`), 3000);
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="geneticresult"
              data={initialResult}
              handleModalClose={handleModalClose}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="date"
              control={control}
              defaultValue={initialResult?.date || null}
              rules={{
                required: "Required",
              }}
              render={({ field: { ref, name, ...field } }) => (
                <DatePicker
                  {...field}
                  required
                  label="Date"
                  inputRef={ref}
                  onChange={(e) => field.onChange(dayjs(e).toISOString())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      error={errors?.date}
                      helperText={errors?.date && errors?.date?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <DogAutocomplete
              label="Dog"
              name="parentDoc"
              options={dogs}
              value={selectedDog}
              required={true}
              error={errors?.parentDoc}
              helperText={errors?.parentDoc && errors?.parentDoc?.message}
              control={control}
            />
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            <Controller
              name="source"
              onChange={([, data]) => data}
              defaultValue={initialResult?.source || null}
              control={control}
              rules={{ required: "Required" }}
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  required
                  label="Source"
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                  options={providers}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  getOptionLabel={(option) => `${option.name}`}
                  onChange={(e, data) => onChange(data)}
                  renderInput={(params) => (
                    <TextField {...params} required label="Source" />
                  )}
                  {...props}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormGroup>
              <InputLabel>Sampling verified</InputLabel>
              <FormControlLabel
                control={
                  <Controller
                    name="samplingVerified"
                    control={control}
                    defaultValue={initialResult?.samplingVerified || false}
                    render={({ field }) => (
                      <Switch
                        {...field}
                        name="samplingVerified"
                        checked={field.value}
                      />
                    )}
                  />
                }
                label={formdata.samplingVerified ? "Yes" : "No"}
              />
              <FormHelperText>
                Has the sample taking been verified by a veterinarian or other
                3rd party?
              </FormHelperText>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5">Results</Typography>
          </Grid>
          <Grid item xs={12}>
            {fields?.length > 0 ? (
              fields.map((field, i) => (
                <React.Fragment key={field.id}>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={"flex-start"}
                    spacing={2}
                    p={2}
                  >
                    <Controller
                      name={`results[${i}].subject`}
                      onChange={([, data]) => data}
                      defaultValue={initialResult?.results[i]?.subject || null}
                      control={control}
                      render={({ field: { onChange, ...props } }) => (
                        <Autocomplete
                          fullWidth
                          label="Subject"
                          renderOption={(props, option) => (
                            <li {...props} key={option._id}>
                              {`${option.name} (${option.marker})`}
                            </li>
                          )}
                          options={testTypes}
                          isOptionEqualToValue={(option, value) =>
                            option._id === value._id
                          }
                          getOptionLabel={(option) =>
                            `${option.name} (${option.marker})`
                          }
                          onChange={(e, data) => onChange(data)}
                          renderInput={(params) => (
                            <TextField {...params} label="Subject" />
                          )}
                          {...props}
                        />
                      )}
                    />
                    <FormControl fullWidth>
                      <InputLabel>Result</InputLabel>
                      <Controller
                        name={`results[${i}].result`}
                        control={control}
                        defaultValue={results[i]?.result || ""}
                        render={({ field }) => (
                          <Select {...field} fullWidth label="Result">
                            {kruValues
                              .filter(
                                (i) => i.category === "genetictest.results"
                              )
                              .map((value) => (
                                <MenuItem key={value._id} value={value._id}>
                                  {value.en.name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <IconButton
                      onClick={() => {
                        if (confirm("Sure you want to remove this result?")) {
                          remove(i);
                        }
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body">
                Add results by clicking the button below
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<Add />}
              onClick={addResult}
              sx={{ float: "right", mt: 2 }}
            >
              Add result
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              name="additionalInfo"
              control={control}
              defaultValue={initialResult?.additionalInfo || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Additional information"
                  error={errors?.additionalInfo}
                  helperText={
                    errors?.additionalInfo && errors?.additionalInfo?.message
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default GenetictestForm;
