import React, { useState } from "react";
import { useParams } from "react-router-dom";

// Components
import { getLitterColumns } from "components/adminDashboardComponents/adminDatagridColumns";

// MUI
import { Grid, Stack, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

// Recoil
import { adminAllUsersState, adminAllLittersState } from "recoil/adminStates";
import { useRecoilValue } from "recoil";

export default function AdminAllLitters() {
  const { organizationPrefix } = useParams();

  const litters = useRecoilValue(adminAllLittersState);
  const users = useRecoilValue(adminAllUsersState);

  const handleRowClick = (params) => {
    window.location = `/${organizationPrefix}/admin/litter/${params.row._id}`;
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent={{ xs: "center", sm: "space-between" }}
            alignItems="center"
            alignContent={"center"}
            spacing={{ xs: 2, sm: 0 }}
          >
            <Typography variant="h2">Registered litters</Typography>{" "}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            autoHeight
            pageSize={50}
            onRowClick={handleRowClick}
            rows={litters}
            getRowId={(row) => row._id}
            columns={getLitterColumns("litter")}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick
          />
        </Grid>
      </Grid>
    </>
  );
}
