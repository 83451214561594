import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { useTranslation, withTranslation } from "react-i18next";

// Icons
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LaunchIcon from "@mui/icons-material/Launch";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";

// MUI
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Divider,
  Collapse,
  Chip,
  Button,
  Paper,
  Typography,
} from "@mui/material";

import DogAvatar from "./DogAvatar";
import { getLinkDisplay } from "constants/helpers";

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

const HighlightedListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
  "&.MuiListItemButton-root": {
    backgroundColor: theme.palette.lightGray.default,
  },
}));

export function LitterCard({ litter, organization }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const males = litter.puppies.reduce(
    (total, puppy) => (total += puppy.sex === "male"),
    0
  );
  const females = litter.puppies.reduce(
    (total, puppy) => (total += puppy.sex === "female"),
    0
  );

  const handleClick = () => {
    if (open === false) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const sireLinkDisplay = getLinkDisplay(litter.sire, organization);
  const damLinkDisplay = getLinkDisplay(litter.dam, organization);

  // Mapping each puppy for the dropdown menu items
  const puppiesMarkup = litter.puppies.map((puppy, i) => (
    <div key={i}>
      <Divider variant="middle" />
      <StyledListButton href={`/${organization.prefix}/dog/${puppy?.regNumber}`}>
        <ListItem>
          <ListItemAvatar sx={{ pr: 2 }}>
            <Chip
              icon={puppy?.sex === "female" ? <FemaleIcon /> : <MaleIcon />}
              label={t(puppy?.sex)}
              variant="outlined"
            />
          </ListItemAvatar>
          <ListItemText primary={puppy?.name} secondary={puppy?.regNumber} />
        </ListItem>
      </StyledListButton>
    </div>
  ));

  return (
    <div>
      <Paper sx={{ mb: 3 }}>
        <List disablePadding>
          <ListItem
            secondaryAction={
              <Button
                href={`/${organization.prefix}/litter/${litter._id}`}
                endIcon={<LaunchIcon />}
                size="small"
              >
                {t("Litter profile")}
              </Button>
            }
          >
            <ListItemText
              primary={
                <Typography variant="body2">
                  {t("DOB")}{" "}
                  {dayjs(litter.dateOfBirth).format("MM.DD.YYYY") || "?"}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <StyledListButton href={`/${organization.prefix}/dog/${sireLinkDisplay?.link}`}>
            <ListItem
              secondaryAction={
                <Chip
                  icon={<MaleIcon />}
                  label={t("Sire")}
                  variant="outlined"
                  size="small"
                />
              }
            >
              <ListItemAvatar sx={{ display: { xs: "none", sm: "flex" } }}>
                <DogAvatar
                  primaryImage={litter.sire?.primaryImage}
                  dogId={litter.sire?._id}
                  alt={litter.sire?.name}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{ pl: 2 }}
                primary={
                  <Typography variant="h5">
                    {litter.sire?.name || t("Not disclosed")}
                  </Typography>
                }
                secondary={sireLinkDisplay?.display}
              />
            </ListItem>{" "}
          </StyledListButton>
          <Divider variant="middle" />
          <StyledListButton href={`/${organization.prefix}/dog/${damLinkDisplay?.link}`}>
            <ListItem
              secondaryAction={
                <Chip
                  icon={<FemaleIcon />}
                  label={t("Dam")}
                  variant="outlined"
                  size="small"
                />
              }
            >
              <ListItemAvatar sx={{ display: { xs: "none", sm: "flex" } }}>
                <DogAvatar
                  primaryImage={litter.dam?.primaryImage}
                  dogId={litter.dam?._id}
                  alt={litter.dam?.name}
                />
              </ListItemAvatar>
              <ListItemText
                sx={{ pl: 2 }}
                primary={
                  <Typography variant="h5">
                    {litter.dam?.name || t("Not disclosed")}
                  </Typography>
                }
                secondary={damLinkDisplay?.display}
              />
            </ListItem>{" "}
          </StyledListButton>
          <Divider />
          <HighlightedListButton onClick={handleClick}>
            <ListItem sx={{ bgcolor: "primary" }}>
              <ListItemText
                primary={
                  <Typography variant="body2">
                    {males || 0} {t("males").toLowerCase()}, {females || 0}{" "}
                    {t("females").toLowerCase()}
                  </Typography>
                }
              />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </HighlightedListButton>

          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {puppiesMarkup}
            </List>
          </Collapse>
        </List>
      </Paper>
    </div>
  );
}
