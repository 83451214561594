import { createNewTreedog, registerDog } from "api/adminRoutes";
import {
  createCheckout,
  createDog,
  createLitter,
  createPendingLitter,
  createStatistic,
  // updatePrimaryImageById
} from "api/privateRoutes";

import {
  compareRole,
  getPaymentAmount,
  getUserManagerPlusOrg,
} from "constants/helpers";

const getRegAdmin = (user, registry) => {
  return compareRole(user.role, "manager") ||
    getUserManagerPlusOrg(user, registry);
};

const getOwner = (user, dog) => {
  let owner = user._id;
  if (getRegAdmin(user, dog.registry)) {
    owner = dog.owner?._id;
  }
  return owner;
};

export const sendLitterRegDataToApi = async (data, user, accessToken) => {
  try {
    // if this user is allowed to set breeder, accept from form
    let breeder = user._id;
    const litter = structuredClone(data);
    const puppyIds = [];
    const regAdmin = getRegAdmin(user, litter.registry);
    const organizationPrefix = litter.registry.prefix;

    if (regAdmin) {
      breeder = litter.breeder?._id;
    }

    const organizationWithReg = {
      organization: litter.registry._id,
      regNumber: null,
      regClass: `reg.classes.limited`,
      regType: `reg.types.litter`,
      prefix: organizationPrefix,
    };

    // Map puppies into correct form
    const mappedPuppies = litter.puppies.map((puppy,index) => {
      let attachments = {};
      if (litter.attachments[`images-${index}`]) {
        attachments.images = litter.attachments[`images-${index}`];
        attachments.images[0].category = "profileImage";
        delete litter.attachments[`images-${index}`];
      }

      // Coat stuff
      const modifiers = []
        .concat(puppy.alocusmodifiers || [])
        .concat(puppy.merlemodifiers || [])
        .concat(puppy.othermodifiers || []);
      const markings = []
        .concat(puppy.whitemarkings || [])
        .concat(puppy.spots || [])
        .concat(puppy.othermarkings || []);
      const coatQualities = []
        .concat(puppy.coatqualities || [])
        .concat(puppy.coatcurliness || []);
    
      return {
        name: puppy.name,
        callname: puppy.callname,
        owners: [breeder],
        microchipNumber: puppy.microchipNumber,
        sex: puppy.sex,
        coat: {
          color: puppy.color,
          markings: markings,
          modifiers: modifiers,
          type: puppy.coattype,
          qualities: coatQualities,
        },
        regType: "reg.types.litter",
        regClass: "reg.classes.limited",
        organizations: [organizationWithReg],
        countryOfOrigin: litter.countryOfOrigin.code,
        breeder: litter.breeder,
        sire: litter.sire._id,
        dam: litter.dam._id,
        declaredTypes: litter.declaredTypes,
        dateOfBirth: litter.dateOfBirth,
        attachments: attachments,
        additionalInfo: litter.additionalInfo,
	      pendingRegistration: ! regAdmin,
      };
    });

    // Create puppies as registrable dogs with pending registration
    for (const puppy of mappedPuppies) {
      const newpuppy = await createDog(puppy, { accessToken });
      puppyIds.push(newpuppy._id);

      if (regAdmin) {
        const regNum = await registerDog(newpuppy._id, organizationPrefix, {
          accessToken,
	    });
	    if (regNum === null || regNum === undefined) {
          throw new Error(
            "Failed to create registration number for puppy in database."
          );
	    }
      }
    }

    // Map litter into correct form
    // Do not send _id
    const { _id, ...transformedLitter } = {
      name: litter.name,
      puppies: puppyIds,
      sire: litter.sire._id,
      dam: litter.dam._id,
      attachments: litter.attachments,
      declaredTypes: litter.declaredTypes,
      dateOfBirth: litter.dateOfBirth,
      breeder: breeder,
      organizations: [organizationWithReg],
      pending: ! regAdmin
    };

    // Do we need to pay?
    const amount = getPaymentAmount(user, "litter", litter.registry);

    if (amount == null) {
      throw new Error(
        "Could not find payment information for " +
          dog.registry.name +
          "."
      );
    }
    
    // Create litter (TODO: don't create if problems)
    const newLitter = regAdmin ?
      await createLitter(transformedLitter, { accessToken }) :
      await createPendingLitter(transformedLitter, { accessToken });

    if (newLitter === null || newLitter._id === undefined || newLitter._id == null) {
      throw new Error("Failed to create new litter in database");
    }

    // Create statistics
    await createStatistic(litter.stats, { accessToken });

    /* FIXME: do we want to do verifications? We can't email currently.
    const verifications = [];
    // Create verification objects based on needed verifications
    if (litter.verificationNeeded && litter.verificationNeeded.sire === "email") {
      const verification = {
        operation: "verifyLitterSire",
        parentDoc: newLitter._id,
        parentDocType: "templitter",
      };
      verifications.push(createVerification(verification, { accessToken }));
    }
    if (litter.verificationNeeded && litter.verificationNeeded.dam === "email") {
      const verification = {
        operation: "verifyLitterDam",
        parentDoc: newLitter._id,
        parentDocType: "templitter",
      };
      verifications.push(createVerification(verification, { accessToken }));
    }
    */

    if (amount == 0) {
      // handle admin registration
      window.location.assign(
        "/" + organizationPrefix + "/litter-registration/" + newLitter._id + "/success"
      );
    } else {
      // Create checkout for payment and redirect to Stripe
      const order = await createCheckout({
        accessToken,
        parentDoc: newLitter._id,
        parentDocType: "litter",
        organizations: [litter.registry._id],
      });
      if (!order.url) throw Error(`Cannot redirect to Stripe`);
      window.location.assign(order.url);
    }
  } catch (error) {
    console.error("Error in constructObject.js: ", error);
    // FIXME possible we have created dogs and failed to register them
    // We should investigate and clean up
    return error;
  }
};

export const sendDogRegDataToApi = async (data, user, accessToken) => {
  try {
    const dog = structuredClone(data);
    const owner = getOwner (user, dog);
    const organizationPrefix = dog.registry.prefix;

    const organizationWithReg = {
      organization: dog.registry._id,
      regNumber: null,
      regClass: dog.class,
      regType: `reg.types.individual`,
      prefix: dog.registry.prefix,
      certificates: [],
    };

    // Map input params to schema names
    dog.owners = [owner];
    dog.organizations = [organizationWithReg];
    dog.countryOfOrigin = dog.countryOfOrigin.code;
    dog.sire = dog.sire?._id;
    dog.dam = dog.dam?._id;

    // Coat stuff
    const modifiers = []
      .concat(dog.alocusmodifiers || [])
      .concat(dog.merlemodifiers || [])
      .concat(dog.othermodifiers || []);
    const markings = []
      .concat(dog.whitemarkings || [])
      .concat(dog.spots || [])
      .concat(dog.othermarkings || []);
    const coatQualities = []
      .concat(dog.coatqualities || [])
      .concat(dog.coatcurliness || []);
    
    dog.coat = {
        color: dog.color,
        markings: markings,
        modifiers: modifiers,
        type: dog.coattype,
        qualities: coatQualities,
      };
      
    // Pending, unlesswe have admin authorization
    dog.pendingRegistration = ! getRegAdmin(user, dog.registry);

    // Do we need to pay?
    const amount = getPaymentAmount(user, "dog", dog.registry);

    if (amount == null) {
      throw new Error(
        "Could not find payment information for " +
          dog.registry.name +
          "."
      );
    }

    // TODO: don't create dog if there are problems
    const newDog = await createDog(dog, { accessToken });

    if (newDog === null || newDog._id === undefined || newDog._id == null) {
      throw new Error("Failed to create new dog in database");
    }

    if (!dog.pendingRegistration) {
      const regNum = await registerDog(newDog._id, organizationPrefix, {
        accessToken,
      });
      if (regNum === null || regNum === undefined) {
        throw new Error(
          "Failed to create registration number for dog in database."
        );
      }
    }

    if (amount == 0) {
      // handle admin/free registration
      window.location.assign("/" + organizationPrefix + "/dog-registration/" + newDog._id + "/success");
    } else {
      // Create checkout for payment and redirect to Stripe
      const order = await createCheckout({
        accessToken,
        parentDoc: newDog._id,
        parentDocType: "dog",
        organizations: [dog.registry._id],
      });
      if (!order.url) throw Error(`Cannot redirect to Stripe`);
      window.location.assign(order.url);
    }
  } catch (error) {
    console.error("Error in constructObject.js: ", error);
    // FIXME possible we have created a dog and failed to register it
    // We should investigate and clean up
    return error;
  }
};

export const sendTreedogAdditionDataToApi = async (data, user, accessToken) => {
  try {
    const dog = structuredClone(data);
    const organizationPrefix = dog.registry.prefix;

    const organizationWithReg = {
      organization: dog.registry?._id,
      regNumber: null,
      regClass: `reg.classes.tree`,
      regType: `reg.types.individual`,
      prefix: organizationPrefix,
    };

    // Map input params to schema names
    dog.organizations = [organizationWithReg];
    dog.countryOfOrigin = dog.countryOfOrigin?.code;

    // Coat stuff
    const modifiers = []
      .concat(dog.alocusmodifiers || [])
      .concat(dog.merlemodifiers || [])
      .concat(dog.othermodifiers || []);
    const markings = []
      .concat(dog.whitemarkings || [])
      .concat(dog.spots || [])
      .concat(dog.othermarkings || []);
    const coatQualities = []
      .concat(dog.coatqualities || [])
      .concat(dog.coatcurliness || []);
    
    dog.coat = {
        color: dog.color,
        markings: markings,
        modifiers: modifiers,
        type: dog.coattype,
        qualities: coatQualities,
      };

    if (!dog.name) {
      dog.name = "Tree Dog";
    }
  
    // TODO: don't create dog if there are problems
    const newDog = await createNewTreedog(dog, { accessToken });

    if (newDog === null || newDog._id === undefined || newDog._id == null) {
      throw new Error("Failed to create new tree dog in database");
    }

    window.location.assign("/${organizationPrefix}/admin/treedog-addition/" + newDog._id + "/success");
  } catch (error) {
    console.error("Error in constructObject.js: ", error);
    return error;
  }
};
