import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { getOrgByPrefixState } from "recoil/publicStates";
import { litterRegistrationState } from "recoil/registrationStates";

// MUI
import {
  Button,
  Grid,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import { Launch } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
  padding: 0,
}));

export default function LitterRegistry() {
  const { organizationPrefix } = useParams();
  const org = useRecoilValue(getOrgByPrefixState(organizationPrefix));


  if (!org) {
    throw new Error(`No organization found with prefix ${organizationPrefix}`);
  }

  const { t } = useTranslation();
  const location = useLocation();
  const targetPath = `/organization/${org.prefix}`;
  const [formData, setFormData] = useRecoilState(litterRegistrationState);

  return (
    <Paper sx={{ mb: 0, p: 3 }}>
      <Grid
        container
        sx={{ mb: 0 }}
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item xs={12} sm={9}>
          { /* This is where org logo would go
          <Box
            sx={{ py: 1, height: 100 }}
            component="img"
            src={FDCLogo}
            alt="{org.name} logo"
          />
          */ }
       <Typography variant="h1">
        {location.pathname === targetPath ? (
          org.name
        ) : (
          <StyledListButton href={targetPath} style={{ textDecoration: 'none' }}>
            {org.name}
          </StyledListButton>
        )}
      </Typography>
           <Typography variant="body1">
            {org.url && <Link href={org.url} style={{ textDecoration: 'none' }}>{org.url}</Link>}
          </Typography>
  
        </Grid>
        
        <Grid item xs={6} sx={{ mb: 0 }}>
        {org && (
          <Button href={org.rulesurl} target="_blank" endIcon={<Launch />}>
            {t("registryGuidelines")}
          </Button>
        )}
      </Grid>
    </Grid>
  </Paper>
  );
}
