import React, { useRef, useState, Suspense } from "react";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

// Recoil
import { useRecoilValue } from "recoil";
import { userState } from "recoil/globalStates";

// MUI
import {
  Table, TableBody, TableCell, TableContainer, TableRow, Paper,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Stack,
} from "@mui/material";
import ArticleIcon from "@mui/icons-material/Article";
import LaunchIcon from "@mui/icons-material/Launch";

import CertificateTemplate from "../registrationCertificates/certificateTemplate";
import PhotoModal from "../dogProfileComponents/PhotoModal";
import OwnershipChangeForm from "./ownershipChangeForm";
import OwnershipFormModal from "./formModal";
import Loader from "elements/Loader";

import { getKruValueLabel } from "constants/helpers";

export default function DogView({ selectedDog }) {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const user = useRecoilValue(userState);

  const [modalOpen, setModalOpen] = useState(false);
  const componentRef = useRef(null);

  const handlePrint = (orgWithReg) => useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `FDDregistration-${orgWithReg?.regNumber}`,
  });

  const handleOwnershipModal = () => {
    if (modalOpen === true) {
      if (confirm(t("confirmModalCloseText"))) {
        setModalOpen(false);
      }
    } else {
      setModalOpen(true);
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <Grid
        container
        spacing={{ xs: 2 }}
        sx={{ p: { xs: 2, sm: 4 } }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Typography variant="h2">{selectedDog?.name}</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <PhotoModal dog={selectedDog} />
        </Grid>
        <Grid item xs={12} sm={8}>
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("FDD number")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.regNumber)} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("microchipNumber")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.microchipNumber)} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("dob")}`} />
              </ListItemIcon>
              <ListItemText
                primary={dayjs(selectedDog?.dateOfBirth).format("MM.DD.YYYY")} />
            </ListItem>
            <Divider variant="middle" />
            <ListItem>
              <ListItemIcon sx={{ pr: 2 }}>
                <ListItemText primary={`${t("sex")}`} />
              </ListItemIcon>
              <ListItemText primary={t(selectedDog?.sex)} />
            </ListItem>
        </Grid>
      </Grid>

      {selectedDog?.organizations?.map((orgWithReg, index) => {
        const showCert = orgWithReg.regClass !== "reg.classes.unregparent";
        const printHandler = handlePrint(componentRef, orgWithReg);
      
        return (
           <>
            <TableContainer key={index} component={Paper}>
              <Table>
                <TableBody>
                  <TableRow hover>
                    <TableCell variant="head">{t("registry")}</TableCell>
                    <TableCell>
                      {orgWithReg.organization.name}
                    </TableCell>
                    <TableCell variant="head">{t("registrationClass")}</TableCell>
                    <TableCell>
                      {getKruValueLabel(orgWithReg.regClass, lang) || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell variant="head">{t("registrationNumber")}</TableCell>
                    <TableCell>{orgWithReg.regNumber || error}</TableCell>
                    <TableCell variant="head">{t("registrationDate")}</TableCell>
                    <TableCell colSpan={3}>
                      {selectedDog.createdAt
                        ? dayjs(selectedDog.createdAt).format("MM.DD.YYYY")
                        : error}
                    </TableCell>
                  </TableRow>
                  <TableRow hover></TableRow>
                  <TableRow hover></TableRow>
                  {/*<TableRow hover>
            {dog.regOther?.length ? (
              <>
                <TableCell variant="head">
                  {t("externalRegistries")}
                </TableCell>
                <TableCell colSpan="3">
                  {dog?.regOther
                    .map((reg) => Object.values(reg).join(" - "))
                    .join(", ")}
                </TableCell>
              </>
            ) : null}
          </TableRow>
          <TableRow hover>
            <TableCell variant="head">{t("certificates")}</TableCell>
            <TableCell>
              {certs
                ? certs.map((cert) => cert.certificate.name).join(", ")
                : error}
            </TableCell>
            </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
            
            <List>
              <ListItemButton href={`/${orgWithReg.organization.prefix}/dog/${orgWithReg.regNumber ? orgWithReg.regNumber : selectedDog._id}`} target="_blank">
                <ListItem>
                  <ListItemIcon>
                    <LaunchIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="h6">{t("showInDatabase")}</Typography>
                    }
                  />
                </ListItem>
              </ListItemButton>
              { showCert && (
                <>
              <Divider variant="middle" />
              <ListItemButton onClick={printHandler}>
                <ListItem>
                  <ListItemIcon>
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        <Stack direction="row" alignItems="center">
                          <Typography variant="h6">
                            {t("downloadDogsRegistrationCertificate")}
                          </Typography>
                        </Stack>
                      </>
                    }
                  />
                </ListItem>
              </ListItemButton>
              </>
              )}
            </List>
            {/* TODO: This seems likely to break if there are multiple registrations due to ref needing to be unique */}
            <Box sx={{ display: "none" }}>
              <CertificateTemplate dog={selectedDog} orgWithReg={orgWithReg} ref={componentRef} />
            </Box>
          </>
      );})}

{ /*
      <Divider variant="middle" />
      <List>
        <ListItemButton onClick={handleOwnershipModal}>
          <ListItem>
            <ListItemIcon>
              <ChangeCircle />
            </ListItemIcon>
            <ListItemText
              primary={
                <>
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h6">{t("changeOwnership")}</Typography>
                  </Stack>
                </>
              }
            />
          </ListItem>
        </ListItemButton>
      </List>
*/ }
      
      <OwnershipFormModal open={modalOpen} onClose={handleOwnershipModal}>
        <OwnershipChangeForm
          id={selectedDog._id}
          setModalOpen={setModalOpen}
          handleModalClose={handleOwnershipModal}
        />
      </OwnershipFormModal>
      </Suspense>
      );
      }
     