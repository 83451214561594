import React, { useState } from "react";
import { useParams } from "react-router-dom";

import dayjs from "dayjs";

import { Grid, TextField, Autocomplete } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { deleteCompetitionResult } from "api/adminRoutes";

import DogAutocomplete from "components/formComponents/dogAutocomplete";
import { citiesFi } from "constants/fiCities";

import {
  accessTokenState,
  snackBarState,
  userState,
} from "recoil/globalStates";
import { adminAllDogsState, adminDogByIdState } from "recoil/adminStates";
import { constSelector, useRecoilState, useRecoilValue } from "recoil";
import { Controller, useForm } from "react-hook-form";
import FormHeader from "components/adminDashboardComponents/formHeader";
import JsonPreviewWidget from "components/adminDashboardComponents/jsonPreviewWidget";
import FormButtons from "components/adminDashboardComponents/formButtons";

function CompetitionResultForm({
  action,
  result,
  emitHandleSubmit,
  handleModalClose,
}) {
  const { organizationPrefix } = useParams();

  const accessToken = useRecoilValue(accessTokenState);
  const [snackbarState, setSnackbarState] = useRecoilState(snackBarState);

  const [initialResult, setInitialResult] = useState(result);

  const dogs = useRecoilValue(adminAllDogsState);
  const [selectedDog, setSelectedDog] = useState(
    useRecoilValue(adminDogByIdState(result?.parentDoc))
  );

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
  } = useForm();

  const formdata = watch();

  const onSubmit = (data) => {
    // set parentDocType, otherwise result's organizations won't be set
    data.parentDocType = "dog";

    emitHandleSubmit(accessToken, data, initialResult?._id);
  };

  const handleDelete = () => {
    if (
      confirm(
        `Are you sure you want to delete this result? This process can NOT be undone!`
      )
    ) {
      const deleteData = async () => {
        await deleteCompetitionResult(initialResult._id, { accessToken }).then(
          (res) => {
            if (res.error) {
              setSnackbarState({
                message: res.message,
                severity: "error",
                open: true,
              });
            } else {
              alert(`Result has been removed.`);
              setTimeout(
                (window.location = `/${organizationPrefix}/admin/dog/${selectedDog._id}`),
                3000
              );
            }
          }
        );
      };
      deleteData();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction={{ xs: "column", sm: "row" }}
          spacing={3}
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <FormHeader
              action={action}
              formType="competitionresult"
              data={initialResult}
              handleModalClose={handleModalClose}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="date"
              control={control}
              defaultValue={initialResult?.date || null}
              rules={{
                required: "Required",
              }}
              render={({ field: { ref, name, ...field } }) => (
                <DatePicker
                  {...field}
                  required
                  label="Date"
                  inputRef={ref}
                  onChange={(e) => field.onChange(dayjs(e).toISOString())}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      error={errors?.date}
                      helperText={errors?.date && errors?.date?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DogAutocomplete
              label="Dog"
              name="parentDoc"
              options={dogs}
              value={selectedDog}
              required={true}
              error={errors?.dog}
              helperText={errors?.dog?.message}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="sport"
              control={control}
              defaultValue={initialResult?.sport || ""}
              rules={{
                minLength: 1,
                required: "Required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Name of the sport"
                  error={errors?.sport}
                  helperText={errors?.sport && errors?.sport?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="class"
              control={control}
              defaultValue={initialResult?.class || ""}
              rules={{
                minLength: 1,
                required: "Required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Name of the class"
                  error={errors?.class}
                  helperText={errors?.class && errors?.class?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="result"
              control={control}
              defaultValue={initialResult?.result || ""}
              rules={{
                required: "Required",
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  required
                  label="Result"
                  error={errors?.result}
                  helperText={errors?.result && errors?.result?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="city"
              render={({ field: { onChange, ...props } }) => (
                <Autocomplete
                  fullWidth
                  freeSolo
                  label="City"
                  options={citiesFi}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      error={errors?.city}
                      helperText={errors?.city && errors?.city?.message}
                    />
                  )}
                  onChange={(e, data) => onChange(data)}
                  {...props}
                />
              )}
              onChange={([, data]) => data}
              defaultValue={initialResult?.city || null}
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="judge"
              control={control}
              defaultValue={initialResult?.judge || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Judge"
                  helperText="Give this data only if judge has given permission for name publishing"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="additionalInfo"
              control={control}
              defaultValue={initialResult?.additionalInfo || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  label="Additional information"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <JsonPreviewWidget formdata={formdata} />
          </Grid>
          <Grid item xs={12}>
            <FormButtons
              action={action}
              handleDelete={handleDelete}
              isSubmitting={isSubmitting}
              isDirty={isDirty}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}
export default CompetitionResultForm;
