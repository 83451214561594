import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Recoil
import { useRecoilValue } from "recoil";
import { organizationsState } from "recoil/publicStates";

import {
  Button,
  Divider,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Business } from "@mui/icons-material";

// Local
import { getOrganizationById } from "api/publicRoutes";
import { userCanRegister } from "constants/helpers";


export default function OrganizationsData({ userPerson }) {
  const { t } = useTranslation();
  const orgs = userPerson.organizations;

  return (
    <>
      <Paper sx={{ mt: 2 }}>
        <List>
          <ListItem>
            <ListItemText>
              <Typography
                variant="h3"
                sx={{ display: "flex", alignItems: "flex-start" }}
              >
                <Business sx={{ mr: 1 }} /> {t("organizations")}
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          {orgs && orgs.length > 0 ? (
            orgs.map((org, i) => (
              <ListItem key={i} sx={{ mb: 1 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <ListItemText>
                      <Link to={`/${org?.organization?.prefix}/home`}
                            target="_blank"
                            rel="noopener noreferrer">
                        {org?.organization?.name}
                      </Link>
                    </ListItemText>
                  </Grid>
                  <Grid item xs={12} sx={{ mb: 0.5 }}>
                    <ListItemText>Role: {org?.role}</ListItemText>
                  </Grid>
                  {userCanRegister(org) && (
                    <Grid item xs={12} sx={{ mt: 1 }}>
                      <Stack direction="column" spacing={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          endIcon={<ChevronRight />}
                          href={`/${org.organization.prefix}/dog-registration`}
                        >
                          {t("registerDog")}
                        </Button> 
                        <Button
                          variant="outlined"
                          color="primary"
                          endIcon={<ChevronRight />}
                          href={`/${org.organization.prefix}/litter-registration`}
                        >
                          {t("registerLitter")}
                        </Button>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            ))
          ) : (
            <Typography variant="body" sx={{ p: 2 }}>
              {t("noOrganizations")}
            </Typography>
          )}
        </List>
      </Paper>
    </>
  );
}
